/* src/components/Common/ContextMenu.css */

.context-menu {
    position: absolute;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    border-radius: 4px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
    z-index: 1000; /* Ensure it's above other elements */
    width: 150px;
    padding: 5px 0;
    box-sizing: border-box;
  }
  
  .context-menu-item {
    padding: 8px 16px;
    cursor: pointer;
    font-size: 0.9rem;
    color: #343a40;
  }
  
  .context-menu-item:hover {
    background-color: #f1f1f1;
  }
  
  .context-menu-item.disabled {
    color: #6c757d;
    cursor: not-allowed;
  }
  
  .context-menu-item.disabled:hover {
    background-color: transparent;
  }
  