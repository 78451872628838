/* src/components/FormBuilder/RowFieldsBuilder.css */

.row-fields-builder {
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 5px;
    margin-top: 15px;
  }
  
  .row-fields-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    color: var(--main-color);
  }
  
  .row-fields-header h3 {
    margin: 0;
    font-size: 1.2em;
  }
  
  .add-row-field-button {
    margin: 0;
  }
  
  .row-fields-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .row-field-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e0e0e0;
    padding: 10px 15px;
    border-radius: 5px;
    background-color: #fafafa;
  }
  
  .row-field-info {
    display: flex;
    flex-direction: column;
  }
  
  .field-label {
    font-weight: 600;
    margin-bottom: 5px;
  }
  
  .field-type {
    font-size: 0.9em;
    color: #666;
  }
  
  .row-field-actions {
    display: flex;
    gap: 10px;
  }
  
  .edit-row-field-button,
  .remove-row-field-button {
    padding: 5px 10px;
  }
  
  .no-row-fields-message {
    text-align: center;
    color: #888;
    font-style: italic;
  }
  
  .row-field-selector {
    margin-bottom: 15px;
  } 