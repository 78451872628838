/* Reuse color variables from your existing design */
:root {
  --main-color: #bb8300;       /* Gold-ish primary color */
  --secondary-color: #31423c;  /* Deep green-ish secondary color */
  --light-bg: #f9f9f9;
  --text-color: #fff;

  --button-bg-color: var(--main-color);
  --button-hover-bg-color: var(--secondary-color);

  --input-border-color: #ddd;
}

/* Overall 2-panel layout */
.unauth-split-container {
  display: flex;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(
    135deg,
    var(--main-color) 20%,
    var(--secondary-color) 100%
  );
}

/* LEFT PANEL */
.unauth-left-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px;
  background: #fff; /* White background */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

  opacity: 0;
  animation: unauthFadePanelLeft 0.4s forwards;
}
@keyframes unauthFadePanelLeft {
  to {
    opacity: 1;
    transform: translateX(0);
  }
  from {
    transform: translateX(-30px);
  }
}

/* Catholicore logo top-left */
.unauth-left-logo {
  position: absolute;
  top: 20px;
  left: 20px;
}
.unauth-left-logo img {
  width: 12vw;
  max-width: 180px;
  height: auto;
}

/* Main content of the left panel */
.unauth-left-content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 700px;
  margin: 80px auto 40px;
  width: 100%;
  text-align: center;
}

/* Title, icon, and message for unauthorized */
.unauth-lock-icon {
  color: var(--main-color);
  font-size: 3rem;
  margin-bottom: 1rem;
}

.unauth-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: var(--main-color);
}

.unauth-message {
  margin-bottom: 1.5rem;
  font-size: 1rem;
  color: #555;
  max-width: 80%;
}

/* Button to go to login */
.unauth-button {
  background-color: var(--button-bg-color);
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 10px 16px;
  font-weight: 600;
  font-size: 0.95rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.unauth-button:hover {
  background-color: var(--button-hover-bg-color);
}

/* RIGHT PANEL */
.unauth-right-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 20px;
  background-color: rgba(255, 255, 255, 0.05);

  opacity: 0;
  animation: unauthFadePanelRight 0.4s forwards;
  animation-delay: 0.1s;
}
@keyframes unauthFadePanelRight {
  to {
    opacity: 1;
    transform: translateX(0);
  }
  from {
    transform: translateX(30px);
  }
}

/* Container for right panel content */
.unauth-right-content {
  max-width: 480px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.13);
  backdrop-filter: blur(5px);
  padding: 1.5rem;
  border-radius: 10px;
  color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
}
.unauth-right-content h3 {
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
}
.unauth-right-content p {
  margin-bottom: 1.5rem;
}

/* Image container */
.unauth-image-box {
  margin-top: 1.5rem;
}
.unauth-image-box img {
  max-width: 100%;
  border-radius: 8px;
}

/* Responsive Adjustments */
@media (max-width: 992px) {
  .unauth-split-container {
    flex-direction: column;
  }
  .unauth-left-panel,
  .unauth-right-panel {
    width: 100%;
    animation: none;
    transform: none;
    opacity: 1;
  }
  .unauth-left-logo {
    position: static;
    text-align: center;
    margin-bottom: 20px;
  }
  .unauth-left-logo img {
    width: 30vw;
    max-width: 150px;
  }
}

@media (prefers-reduced-motion: reduce) {
  .unauth-left-panel,
  .unauth-right-panel {
    animation: none !important;
  }
  .unauth-lock-icon,
  .unauth-button:hover {
    transition: none !important;
  }
}
