/* src/pages/TeamsPage.css */

.teams-page-container {
  background-color: #fff;
  padding: 30px;
  border-radius: var(--border-radius);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  max-width: var(--max-width);
  margin: 20px auto;
  position: relative;
}

/* Header Container */
.teams-page-header {
  display: flex;
  align-items: center;
  justify-content: center; /* Centers the header text */
  margin-bottom: 20px;
  position: relative;
}

.teams-page-header h2 {
  margin: 0;
  color: var(--main-color);
  font-size: 2rem;
  font-weight: var(--font-weight-bold);
  text-align: center;
}

/* The gear icon in the top-right corner */
.team-types-gear-button {
  position: absolute;
  right: 0;
  top: 0;
}

/* If no team types exist */
.no-team-types-cta {
  text-align: center;
  margin-top: 40px;
  padding: 30px;
  border: 1px dashed #ccc;
  border-radius: 8px;
  background: #f9f9f9;
}

.no-team-types-cta h3 {
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
  color: var(--main-color);
}

.no-team-types-cta .cta-description {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: #555;
}

/* Tabs container spacing */
.teams-page-tabs {
  margin-bottom: 20px;
}

/* Loading spinner container */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
