/* src/pages/FormsPage.css */

/* Forms Page Container */
.forms-page-container {
    background-color: #fff;
    padding: 30px;
    border-radius: var(--border-radius);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    max-width: var(--max-width);
    margin: 20px auto;
  }
  
  /* Header Container */
  .forms-page-container .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  /* Header Title */
  .forms-page-container .header-container h2 {
    flex: 1;
    text-align: center;
    margin: 0;
    color: var(--main-color);
    font-size: 2rem;
    font-weight: var(--font-weight-bold);
  }
  
  /* Loading Container */
  .loading-container {
    display: flex;                  /* Establishes flex container */
    justify-content: center;        /* Centers items horizontally */
    align-items: center;            /* Centers items vertically */
    height: 100vh;                  /* Optional: Sets container height to full viewport */
  }
  
  /* Error Message */
  .error-message {
    color: red;
    text-align: center;
  }
  