/* src/components/ProfilePage/ProfilePage.css */

/* Container for the "loading" spinner */
.profile-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}

/* 
   Left panel styles that are specific to ProfilePage
   such as the .profile-picture-container, .user-name, 
   and .groups-section
*/
.profile-picture-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.user-name {
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;
  color: #333;
}

.groups-section {
  width: 100%;
  margin-top: 1rem;
}

.groups-section h4 {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  text-align: left;
}

.left-groups-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.group-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.group-item:hover {
  background-color: #f3f3f3;
}

.group-item.selected {
  background-color: #e0f0ff;
}

.group-thumbnail {
  width: 40px;
  height: 40px;
  border-radius: 200px;
  object-fit: cover;
  border: 1px solid #ccc;
}

/* 
   Right panel form styles and states 
*/
.profile-page-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.response-details .half-width {
  flex: 1;
}

.profile-actions {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1rem;
}

/* "No Member Form found" message styles */
.no-form-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  color: #ff4d4f;
}

.no-form-message p {
  margin-top: 0.5rem;
  font-size: 1.2rem;
  font-weight: 500;
}

/* Loading spinner when a group is selected */
.no-form-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.no-form-loading p {
  margin-top: 0.5rem;
  font-size: 1.2rem;
  color: #555;
}

/* Error message styling */
.no-form-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  color: #ff4d4f;
}

.no-form-error p {
  margin-top: 0.5rem;
  font-size: 1.2rem;
  font-weight: 500;
}

/* 
   For narrower screens, you may want the .form-row elements to stack
*/
@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
    gap: 0.75rem;
  }
}

@media (max-width: 480px) {
  .profile-page-form {
    gap: 0.75rem;
  }
  .form-row {
    gap: 0.5rem;
  }
  .profile-actions {
    justify-content: center;
  }
}
