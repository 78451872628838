:root {
  --main-color: #bb8300; /* adjust if needed */
  --secondary-color: #31423c;
  --bg-color: #f9f9f9;
  --card-bg: #fff;
  --text-color: #333;
  --border-radius: 8px;
  --box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* General Form Styles */
.roles-modal-form {
  display: flex;
  flex-direction: column;
}

.roles-tab-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

.general-tab-content {
  background: var(--card-bg);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 20px;
}

/* Icon Wrapper (still looks like a profile image preview) */
.icon-wrapper {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 15px auto;
  position: relative;
  box-shadow: inset 0 0 8px rgba(0,0,0,0.05);
}

/* Icon Selector Group */
.icon-selector-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.roles-label {
  font-weight: bold;
  font-size: 1rem;
  color: #555;
  margin-bottom: 8px;
}

/* Left-aligned fields */
.roles-input-group.left-aligned {
  align-items: flex-start;
}

.roles-input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
  font-size: 0.95rem;
}

.roles-input-group input,
.roles-input-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: var(--border-radius);
  font-size: 0.95rem;
  background: #fff;
  transition: border-color 0.2s;
}

.roles-input-group input:hover,
.roles-input-group textarea:hover {
  border-color: var(--main-color);
}

.roles-input-group input:focus,
.roles-input-group textarea:focus {
  outline: none;
  border-color: var(--main-color);
}

/* Submit Button */
.roles-submit-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Permissions Section */
.permissions-label {
  margin-bottom: 0;
  font-size: 1.1rem;
  color: var(--main-color);
}

.permissions-grid {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(1, 1fr);
}

/* Permission Module Styles */
.permission-module {
  display: flex;
  flex-direction: column;
}

/* Module Card Styles */
.module-card {
  position: relative;
  background: #fff;
  color: var(--main-color);
  border: 1px solid #ddd;
  border-radius: var(--border-radius);
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
  min-height: 120px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.module-card:hover {
  transform: translateY(-3px);
}

.module-card.selected {
  background: var(--main-color);
  color: #fff;
  border: 1px solid var(--main-color);
}

/* Module Checkbox */
.permission-card-header {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 1rem;
}

.permission-card-icon {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.permission-card-label {
  font-size: 1rem;
  font-weight: bold;
}

.permission-card-checkbox {
  font-size: 1rem;
}

/* Sub-Permissions */
.sub-permissions {
  margin-top: 10px;
  width: 100%;
  border-top: 1px solid #ddd;
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.sub-permission-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
  padding: 5px 10px;
  border: 1px solid var(--main-color);
  border-radius: var(--border-radius);
  background-color: #fff;
}

.sub-permission-item:hover {
  background-color: #f0f0f0;
}

.sub-permission-item.selected {
  background-color: var(--main-color);
  color: #fff;
  border-color: #fff;
}

.sub-permission-checkbox {
  margin-right: 8px;
  font-size: 1rem;
}

.sub-permission-label {
  font-size: 0.95rem;
}

/* Disabled Button Styles */
.action-button[disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}

.action-button[disabled]:hover {
  background-color: #ccc;
}

/* Responsive Adjustments */

/* Tablets */
@media (min-width: 600px) and (max-width: 1023px) {
  .permissions-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Desktops */
@media (min-width: 1024px) {
  .permissions-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
