.event-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #ffffff;
  position: relative;
}

.event-form .form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.event-form .form-field {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
}

.event-form .form-field.half-width {
  flex: 1 1 48%;
}

.event-form .form-field.full-width {
  flex: 1 1 100%;
}

.event-form .error-message-field {
  color: red;
  font-size: 0.85rem;
  margin-top: 5px;
}

.full-width.error-message-field {
  flex: 1 1 100%;
}

.event-form .form-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.event-form .delete-button-container {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.event-form .delete-button-container .action-button {
  width: auto;
  height: auto;
  padding: 5px;
}

.event-form .delete-button-container .dropdown-menu button {
  width: 100%;
  background: none;
  border: none;
  padding: 10px;
  text-align: left;
  color: var(--main-color);
  font-size: 1rem;
  cursor: pointer;
}

.dropdown-menu button:hover {
  background-color: var(--main-color) !important;
  color: #fff !important;
}

.status-actions {
  margin-top: 20px;
}

.recurring-days-row {
  justify-content: left;
}

.recurring-days-row .form-field {
  align-items: left;
  text-align: left;
}

.recurring-days-row .checkbox-field .checkbox-label {
  margin-bottom: 10px;
}

.checkbox-field .checkbox-options {
  justify-content: center;
}

.checkbox-field .checkbox-options.horizontal {
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .event-form .form-row {
    flex-direction: column;
  }

  .event-form .form-field.half-width {
    flex: 1 1 100%;
  }
}
