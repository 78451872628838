/* Root variables */
:root {
  --main-color: #bb8300;
  --secondary-color: #31423c;
  --input-border-color: #ddd;
  --button-bg-color: var(--main-color);
  --button-hover-bg-color: var(--secondary-color);
  --text-color: #fff;
}

/* Container for Step 5 form */
.step5-container {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

/* Error message styling */
.error-message {
  color: rgb(96, 8, 8);
  font-size: 0.9em;
  margin-bottom: 10px;
  text-align: left;
}

/* Summary wrapper */
.sign-up-summary {
  text-align: left;
  margin-top: 20px;
}

.sign-up-summary h3 {
  margin-bottom: 20px;
  color: var(--main-color);
  text-align: center;
}

.summary-section {
  margin-bottom: 30px;
}

.summary-section h4 {
  margin-bottom: 10px;
  color: var(--secondary-color);
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

.summary-section table {
  width: 100%;
  border-collapse: collapse;
}

.summary-section th,
.summary-section td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #eaeaea;
}

.summary-section th {
  width: 30%;
  color: #555;
}

/* Color box for the organization color preview */
.color-box {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  vertical-align: middle;
  border: 1px solid #ccc;
}

/* Buttons => finish & back */
.sign-up-submit-button {
  width: 100%;
  padding: 12px 15px;
  border: none;
  border-radius: 5px;
  background-color: var(--button-bg-color);
  color: #fff;
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.sign-up-submit-button:hover {
  background-color: var(--button-hover-bg-color);
}

.sign-up-back-button {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid var(--main-color);
  border-radius: 5px;
  background-color: #fff;
  color: var(--main-color);
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.sign-up-back-button:hover {
  background-color: var(--main-color);
  color: #fff;
}

/* Profile picture preview styling */
.profile-picture-wrapper {
  margin: 20px auto;
  width: 140px;  /* Fixed width */
  height: 140px; /* Fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  background-size: cover;
}

.profile-picture-preview {
  width: 120px;
  height: 120px;
  object-fit: contain;       /* Changed from "cover" to "contain" */
  object-position: center;   /* New property to center the image */
  border: 2px solid #ddd;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* -------------- NEW FINAL-STEP STYLING -------------- */
.final-step {
  text-align: center;
  margin-top: 40px;
}

.thank-you-container {
  margin-top: 20px;
  background-color: #faf9f6;
  padding: 20px;
  border-radius: 5px;
  display: inline-block;
  max-width: 500px;
}

.thank-you-container h3 {
  color: var(--main-color);
  margin-bottom: 10px;
  font-size: 1.6em;
}

.thank-you-container p {
  margin: 0 0 20px 0;
  font-size: 1.05em;
  color: #333;
  line-height: 1.5em;
}

.sign-up-home-button {
  background-color: var(--secondary-color);
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 12px 16px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.sign-up-home-button:hover {
  background-color: #222;
}
