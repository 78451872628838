/* Root variables (duplicate in each step or move to a shared CSS file) */
:root {
  --main-color: #bb8300;
  --secondary-color: #31423c;
  --input-border-color: #ddd;
  --button-bg-color: var(--main-color);
  --button-hover-bg-color: var(--secondary-color);
  --text-color: #fff;
}

/* New container class to limit the form width */
.step1-container {
  width: 100%;
  max-width: 500px; /* Set a reasonable max width (adjust as needed) */
  margin: 0 auto;   /* Center the container horizontally */
}

/* Basic form container styling */
.sign-up-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
  margin-top: 20px;
}

.sign-up-input-group {
  margin-bottom: 15px;
  text-align: left;
}

.sign-up-input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: var(--main-color);
}

.sign-up-input-group input {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid var(--input-border-color);
  border-radius: 5px;
  font-size: 1em;
  background-color: #fff;
  box-sizing: border-box;
}

/* Primary submit button */
.sign-up-submit-button {
  width: 100%;
  padding: 12px 15px;
  border: none;
  border-radius: 5px;
  background-color: var(--button-bg-color);
  color: #fff;
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.sign-up-submit-button:hover {
  background-color: var(--button-hover-bg-color);
}

/* Error / Success messages */
.error-message {
  color: rgb(96, 8, 8);
  font-size: 0.9em;
  margin-bottom: 10px;
  text-align: left;
}

.success-message {
  color: var(--main-color);
  font-size: 0.9em;
  margin-bottom: 10px;
  text-align: left;
}
