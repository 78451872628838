/* src/components/Authentication/ResetPassword.css */

/* Title styling to match your sign-in title class */
.sign-in-title {
  color: var(--main-color);
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

/* Error message styling */
.reset-password-error {
  color: red;
  margin-bottom: 1rem;
}

/* The reset password form */
.reset-password-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Group containing the two password inputs */
.reset-password-input-group {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.reset-password-input-group input {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
}

/* Submit button */
.reset-password-submit-button {
  width: 100%;
  padding: 12px 15px;
  border: none;
  border-radius: 5px;
  background-color: var(--main-color);
  color: #fff;
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.reset-password-submit-button:hover {
  background-color: var(--secondary-color);
  color: #fff;
}

/* If you need a .link-button style for 'Back to Login', 
   reuse or define it here (same as Login/PasswordRecovery) */
.link-button {
  margin-top: 1rem;
  background-color: #fff;
  color: var(--main-color);
  border: 1px solid var(--main-color);
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 0.95rem;
  font-weight: 600;
  text-align: center;
  transition: background-color 0.3s ease;
}

.link-button:hover {
  background-color: var(--main-color);
  color: #fff;
}

/* Modal message content */
.reset-password-message {
  margin-top: 1rem;
  font-size: 1rem;
  line-height: 1.4;
}
