.user-menu {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 100;
}

.user-menu-button {
  position: relative;
  background: radial-gradient(circle at center, var(--main-color) 0%, #000 100%);
  border: none;
  padding: 8px 12px;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 25px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  overflow: hidden; /* Ensures pseudo-element doesn't overflow */
}

.user-menu-button::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* Inverted radial gradient for hover state */
  background: radial-gradient(circle at center, #000 0%, var(--main-color) 100%);
  opacity: 0;
  transition: opacity 0.5s linear;
  z-index: 1; /* Places pseudo-element above background but below content */
  border-radius: inherit; /* Matches the button's border radius */
}

.user-menu-button:hover::before {
  opacity: 1; /* Show the inverted gradient on hover */
}

.user-menu-button:hover {
  /* Optional hover effects */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.user-menu-button > * {
  position: relative;
  z-index: 2; /* Ensures content is above the pseudo-element */
}

.user-menu-button:disabled {
  cursor: not-allowed;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-photo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.user-full-name {
  margin-left: 10px;
  font-size: 0.95em;
  color: #fff;
  font-weight: 500;
  white-space: nowrap;
}

.caret-icon {
  margin-left: 8px;
  font-size: 0.9em;
  color: #fff;
  transition: transform 0.3s ease;
}

.caret-icon.open {
  transform: rotate(180deg);
}

/* User Menu Dropdown */
.user-menu-dropdown {
  position: absolute;
  top: 50px;
  right: 0;
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  min-width: 180px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  pointer-events: none;
  transition: max-height 0.4s ease, opacity 0.3s ease;
}

.user-menu-dropdown.slide-down {
  max-height: 300px; /* increase if needed for more items */
  opacity: 1;
  pointer-events: auto;
}

.user-menu-dropdown.slide-up {
  max-height: 0;
  opacity: 0;
  pointer-events: none;
}

/* Buttons inside the dropdown */
.user-menu-dropdown button {
  width: 100%;
  padding: 12px 20px;
  background-color: transparent;
  border: none;
  text-align: left;
  cursor: pointer;
  font-size: 0.95em;
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease, color 0.2s ease;
  color: #333333;
}

/* Hover for dropdown buttons */
.user-menu-dropdown button:hover {
  background-color: var(--main-color);
  color: #ffffff;
}

/* Match anchor <Link> styles to look the same as the buttons */
.user-menu-dropdown a.dropdown-button {
  width: 100%;
  padding: 12px 20px;
  background-color: transparent;
  border: none; /* no underline or border */
  text-align: left;
  cursor: pointer;
  font-size: 0.95em;
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease, color 0.2s ease;
  color: #333333;
  text-decoration: none;
}

.user-menu-dropdown a.dropdown-button:hover {
  background-color: var(--main-color);
  color: #ffffff;
}

/* Icon spacing in the menu */
.menu-icon {
  margin-right: 12px;
  font-size: 1.1em;
}

/* Responsive / Mobile adjustments */
@media (max-width: 600px) {
  .user-menu-button {
    padding: 5px 8px;
  }

  .user-photo {
    width: 24px;
    height: 24px;
  }

  .user-full-name {
    display: none; /* Hide full name on mobile to save space */
  }

  .caret-icon {
    margin-left: 5px;
  }

  .user-menu-dropdown {
    min-width: 160px;
    right: -10px;
  }

  .user-menu-dropdown button,
  .user-menu-dropdown a.dropdown-button {
    padding: 10px 15px;
    font-size: 0.85em;
  }

  .menu-icon {
    margin-right: 8px;
    font-size: 0.95em;
  }
}
