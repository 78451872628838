/* src/components/Common/TwoPanelLayout.css */

.two-panel-layout-container {
  display: flex;
  width: 100%;
  background-color: #fff; /* or inherit */
}

/* Left panel => narrower */
.two-panel-left {
  flex: 0 0 300px; /* adjust as desired (e.g. 200px, 250px) */
  border-right: 1px solid #ccc;
  background-color: #f8f8f8;
  padding: 1rem;
  box-sizing: border-box;
  overflow-y: auto; /* if needed */
}

/* Right panel => takes remaining space */
.two-panel-right {
  flex: 1;
  padding: 1.5rem;
  box-sizing: border-box;
  overflow-y: auto; /* if needed */
}

/* Responsive stacking on small screens */
@media (max-width: 768px) {
  .two-panel-layout-container {
    flex-direction: column;
  }
  
  .two-panel-left {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #ccc;
  }

  .two-panel-right {
    width: 100%;
  }
}
