/* Tabs Container */
.tabs {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping on small screens */
  border-bottom: 2px solid #dee2e6;
  margin-bottom: 20px;
  gap: 10px; /* Add spacing between tabs when wrapping */
}

/* Individual Tab */
.tab {
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 600;
  color: #6c757d;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center; /* Align text and icon vertically */
  justify-content: center; /* Center content */
  white-space: nowrap; /* Prevent text wrapping */
}

/* Active Tab */
.tab.active {
  border-bottom: 2px solid var(--main-color);
  color: var(--main-color);
}

/* Hover Effect */
.tab:hover {
  background-color: #e9ecef;
}

/* Tab Icon */
.tab-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
}

/* Blinking Animation for Tabs with Missing Fields */
@keyframes blink {
  0% {
    color: #6c757d;
    border-bottom-color: #6c757d;
  }
  50% {
    color: red;
    border-bottom-color: red;
  }
  100% {
    color: #6c757d;
    border-bottom-color: #6c757d;
  }
}

/* Apply blinking animation when the 'blink' class is added */
.tab.blink {
  animation: blink 1s infinite;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .tab {
    padding: 8px 15px; /* Reduce padding for smaller screens */
    font-size: 0.9rem; /* Adjust font size */
  }
  
  .tabs {
    gap: 8px; /* Reduce gap between tabs */
  }
}

@media (max-width: 576px) {
  .tab {
    padding: 6px 10px; /* Further reduce padding for very small screens */
    font-size: 0.85rem; /* Further adjust font size */
  }
}
