.form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.form-group label {
  display: inline-block; /* Ensure label and asterisk stay on the same line */
  font-weight: 600;
  font-size: 1rem;
  white-space: nowrap; /* Prevent the label from wrapping to next line */
  margin-bottom: 5px;
}

.required-asterisk {
  color: red;
  margin-left: 4px;
  display: inline;
}

.select-field.has-error label {
  color: red;
}

.error-message {
  color: red;
  font-size: 0.875rem;
  margin-top: 5px;
}

/* Custom styles for react-select */
.react-select-container {
  width: 100%;
}

.react-select__control {
  border-color: #ccc;
}

.select-field.has-error .react-select__control {
  border-color: red;
}

.react-select__control--is-focused {
  border-color: #007bff;
  box-shadow: 0 0 0 1px #007bff;
}

.react-select__menu {
  z-index: 9999;
}
