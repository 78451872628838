.toggle-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 36px; 
    height: 36px;
    background-color: #ccc; /* Light gray when inactive */
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.1s ease, background-color 0.1s ease;
  }
  
  /* Active state uses main theme color */
  .toggle-button--active {
    background-color: var(--main-color);
  }
  
  /* 3D press animation on click */
  .toggle-button:active {
    transform: scale(0.9);
  }
  
  /* If you'd like to place the icon or text inside */
  .toggle-button-icon {
    font-size: 16px;
  }
  