/* src/components/MessageSender/MessageInput.css */

/* Styled text area */
.textarea-message {
    width: 100%;
    min-height: 150px;
    max-height: 300px;
    padding: 10px;
    font-size: 1rem;
    font-family: inherit;
    color: #171a1e;
    background-color: var(--background-color);
    border: 1px solid #ccc;
    border-radius: var(--border-radius);
    resize: vertical;
    box-sizing: border-box;
  }
  
  /* Ensure the text area is responsive */
  @media (max-width: 768px) {
    .textarea-message {
      font-size: 0.9rem;
    }
  }
  