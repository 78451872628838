/* src/components/Toast.css */

.toast {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 250px;
    padding: 16px 24px;
    border-radius: 8px;
    color: #fff;
    margin-top: 10px; /* Space between toasts */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    animation: fadeInOut 5000ms forwards;
  }
  
  .toast-success {
    background-color: #28a745; /* Green */
  }
  
  .toast-error {
    background-color: #dc3545; /* Red */
  }
  
  .toast-info {
    background-color: #17a2b8; /* Blue */
  }
  
  .toast-close-button {
    background: transparent;
    border: none;
    color: #fff;
    font-size: 1.2em;
    cursor: pointer;
    line-height: 1;
  }
  
  .toast-content {
    display: flex;
    align-items: center;
  }
  
  .toast-icon {
    margin-right: 8px;
    font-size: 1.2em;
  }
  
  /* Combined Fade In and Fade Out Animation for Toasts */
  @keyframes fadeInOut {
    0% {
      opacity: 0;
      transform: translateY(20px); /* Start slightly below */
    }
    10% {
      opacity: 1;
      transform: translateY(0); /* Slide up to original position */
    }
    90% {
      opacity: 1;
      transform: translateY(0); /* Remain visible */
    }
    100% {
      opacity: 0;
      transform: translateY(20px); /* Slide back down */
    }
  }
  