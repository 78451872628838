/* src/pages/MembersPage.css */

/* Main container styling */
.members-page-container {
  background-color: #fff;
  padding: 30px;
  border-radius: var(--border-radius);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  max-width: var(--max-width);
  margin: 20px auto;
}

/* Header container */
.members-page-container .header-container {
  position: relative;         /* Allows absolute positioning of the button */
  display: flex;
  justify-content: center;    /* Centers the heading horizontally */
  align-items: center;
  margin-bottom: 20px;        /* Adjust as needed */
}

/* Header title styling */
.members-page-container .header-container h2 {
  margin: 0;                  /* Remove default margins */
  font-size: 2rem;
  font-weight: var(--font-weight-bold);
  color: var(--main-color);
}

/* Configuration / More Options button */
.members-page-container .config-button {
  position: absolute;         /* Anchor the button to the top-right corner */
  top: 0;
  right: 0;
}

/* Loading container */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;              /* Optional: sets container height to full viewport */
}
