/* src/components/TeamsTable/TeamsTable.css */

.teams-table-container {
    padding: 10px 0;
  }
  
  .record-count {
    margin-bottom: 10px;
    color: var(--text-color);
  }
  
  .teams-header-actions {
    display: flex;
    align-items: center;
    gap: 10px;
  }