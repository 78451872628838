/* src/components/FormBuilder/Fields/EmailField.css */


.form-group {
    margin-bottom: 1rem;
  }
  
  label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .required {
    color: red;
    margin-left: 0.25rem;
  }
  
  .form-control {
    width: 100%;
    padding: 0.5rem;
    box-sizing: border-box;
  }
  
  .is-invalid {
    border-color: red;
  }
  
  .invalid-feedback {
    color: red;
    margin-top: 0.25rem;
    font-size: 0.875rem;
  }