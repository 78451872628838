/* src/components/Layout.css */

.layout-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .loading-screen,
  .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .loading-screen {
    z-index: 2;
    opacity: 1;
    transition: opacity 0.4s ease-in-out;
    pointer-events: auto;
  }
  
  .loading-screen.fade-out {
    opacity: 0;
  }
  
  .content {
    z-index: 1;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
    pointer-events: none;
  }
  
  .content.visible {
    opacity: 1;
    pointer-events: auto;
  }
  