/* src/components/Settings/ProgressBar.css */

/* Progress Bar Styling */
.progress-bar {
    width: 100%;
    background-color: var(--hover-bg-color);
    border-radius: var(--border-radius);
    overflow: hidden;
    margin-bottom: 10px;
    height: 12px;
  }
  
  .progress-bar-inner {
    height: 100%;
    background-color: var(--main-color);
    width: 0%;
    transition: width 0.3s ease;
  }
  