/* src/components/Common/Table.css */

/* Base Styles */
.table-wrapper {
  overflow-x: auto;
}

.generic-table {
  width: 100%;
  border-collapse: collapse;
  margin: 15px 0;
  font-size: var(--font-size);
  font-family: var(--font-family);
}

.generic-table tbody {
  background-color: #fff;
}

.generic-table thead tr {
  background-color: var(--table-header-bg);
  color: var(--table-header-text-color);
  text-align: left;
}

.generic-table th,
.generic-table td {
  padding: 12px 16px;
  min-height: 50px;
}

.generic-table tbody tr:nth-of-type(even) {
  background-color: var(--table-row-bg-alt);
}

.generic-table tbody tr:last-of-type {
  border-bottom: 2px solid var(--table-header-bg);
}

.generic-table tbody tr:hover {
  background-color: #e9ecef;
}

/* Sortable column header */
.sortable {
  cursor: pointer;
  user-select: none;
}

.sort-indicator {
  margin-left: 5px;
  font-size: 0.8em;
}

/* Actions Cell */
.actions-cell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.profile-picture {
  height: 40px;
  width: 40px;
  background-color: var(--main-color);
  border-radius: 100px;
}

/* Responsive Adjustments */
@media (min-width: 769px) and (max-width: 1024px) {
  .generic-table th,
  .generic-table td {
    padding: 10px 14px;
    font-size: 0.95em;
  }

  .actions-cell {
    align-items: center;
    gap: 5px;
  }
}

@media (max-width: 768px) {
  .hide-in-mobile {
    display: none;
  }

  .generic-table th,
  .generic-table td {
    padding: 8px 10px;
  }

  .actions-cell {
    align-items: center;
    gap: 5px;
    justify-content: flex-start;
  }
}

.clickable-value {
  cursor: pointer;
  color: var(--main-color);
}

.clickable-value:hover {
  text-decoration: underline;
}