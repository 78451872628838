/* src/components/Roles.css */

/* Match style with Users */
.roles-table-container {
  padding: 20px;
  background-color: #fff;
}

.roles-table-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.roles-table-header h2 {
  flex: 1;
  text-align: center;
  margin: 0;
  color: var(--main-color);
  font-size: 2rem; /* match Users font size if desired */
  font-weight: normal; 
}

.roles-header-actions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  justify-content: center; /* or space-between, etc. */
}

.record-count {
  margin-bottom: 20px;
  color: #555;
}

/* Table styling, same as your prior code */
.roles-table-container table {
  width: 100%;
  border-collapse: collapse;
}

.roles-table-container th,
.roles-table-container td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.roles-table-container th {
  background-color: var(--main-color);
  color: #fff;
}

.role-name-cell {
  display: flex;
  align-items: center;
  gap: 8px;
}

.role-icon {
  color: var(--main-color);
  font-size: 1.2rem;
}
