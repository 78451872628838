/* src/components/FormBuilder/Fields/RadioButtonField.css */

.radio-group label {
    display: block;
    font-weight: 600;
    margin-bottom: 5px;
  }
  
  .radio-options {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .radio-option {
    display: flex;
    align-items: center;
  }
  
  .radio-option input {
    margin-right: 8px;
  }
  