/* Remove or comment out the old column/row toggles for .form-table-header
   so they don't fight these new styles. */

/* Mimic the .members-header-actions style from MembersTable */
.form-table-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px; /* or 16px if you like */
}

/* Let the SearchBar expand and push the button to the right */
.form-table-header .search-bar {
  flex: 1;
  margin-right: 10px; /* optional spacing between input & button */
}

/* Make sure your ActionButton is styled similarly */
.form-table-header .action-button {
  flex-shrink: 0;
  background-color: var(--main-color) !important;
  color: var(--button-text-color) !important;
  border: none !important;
  padding: 10px 20px !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  font-size: 1em !important;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.form-table-header .action-button:hover {
  background-color: var(--main-color) !important;
  transform: translateY(-2px);
}

/* (Optional) If you want them to *stay* on one row at all screen sizes,
   you don't even need extra media queries—this layout is already responsive. */
