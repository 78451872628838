/* src/components/Common/CustomTooltip.css */

/* Basic fade-in animation */
@keyframes tooltipFadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -110%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -100%);
  }
}

/* Wrapper around the trigger element */
.tooltip-trigger-wrapper {
  display: inline-block; /* So the element doesn't force full width */
  position: relative;
}

/* Our absolutely-positioned tooltip container (rendered in a Portal) */
.enhanced-tooltip {
  position: absolute;
  background-color: #333;
  color: #fff;
  font-size: 0.85em;
  line-height: 1.4;
  padding: 8px 12px;
  border-radius: 6px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

  /* Center by default (translateX(-50%)) and place it above the trigger (translateY(-100%)) */
  transform: translate(-50%, -100%);
  transform-origin: center bottom;

  /* Fade in */
  animation: tooltipFadeIn 0.2s ease forwards;

  z-index: 999999; /* Large to ensure it's always on top */
  pointer-events: none; /* So it doesn't block pointer on underlying elements */
}

/* The arrow pointing downward toward the trigger element */
.enhanced-tooltip::after {
  content: "";
  position: absolute;
  bottom: -6px; /* Place the arrow just below the tooltip box */
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}
