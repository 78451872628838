/* src/components/MainScreen.css */

@import '../../styles/variables.css';

/* Reset some default styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-screen-app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--background-color);
  font-family: var(--font-family);
}

/* Fade-in and Fade-out Animations */
.fade-in {
  animation: fadeIn 300ms forwards;
}

.fade-out {
  animation: fadeOut 300ms forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Slightly move up */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px); /* Slightly move down */
  }
}

/* Smooth transition for the main content */
.main-content {
  flex: 1;
  max-width: var(--max-width);
  margin: 20px auto;
  width: 100%;
  padding: 0 20px;
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
  padding-bottom: 60px; /* Prevent content from being hidden behind the footer */
}

.record-count
{
  color: var(--main-color) !important;
}

@media (max-width: 768px) {
  .main-content {
    padding: 0 10px;
  }
}