/* src/components/MessageSender/ImagePreview.css */

/* Parent container for image preview */
.image-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    width: 120px;
    background-color: #f0f0f0;
    border-radius: var(--border-radius);
    overflow: hidden;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 5px;
    margin: 0 auto; /* Center the image preview */
  }
  
  /* Image styling within the container */
  .image-container img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: calc(var(--border-radius) - 2px);
  }
  
  /* Remove image button */
  .remove-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px 8px;
    background-color: var(--danger-color);
    color: #fff;
    border: none;
    border-radius: 0 0 0 var(--border-radius);
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  /* Hover effect for remove button */
  .remove-button:hover {
    background-color: var(--danger-hover-color);
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .image-container {
      height: 100px;
      width: 100px;
    }
  }
  