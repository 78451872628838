/* Form Layout */
.password-recovery-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.password-recovery-input-group input {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
}

.password-recovery-submit-button {
  width: 100%;
  padding: 12px 15px;
  border: none;
  border-radius: 5px;
  background-color: var(--main-color);
  color: #fff;
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.password-recovery-submit-button:hover {
  background-color: var(--secondary-color);
  color: #fff;
}

/* Container for the link-button */
.password-recovery-links-container {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Reuse the .link-button style from Login or define here */
.link-button {
  background-color: #fff;
  color: var(--main-color);
  border: 1px solid var(--main-color);
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 0.95rem;
  font-weight: 600;
  text-align: center;
  transition: background-color 0.3s ease;
}

.link-button:hover {
  background-color: var(--main-color);
  color: #fff;
}

/* Title styling (if you want it consistent with the login's styling) */
.sign-in-title {
  color: var(--main-color);
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

/* Enhanced Modal Styling */
.enhanced-password-recovery-modal {
  text-align: center;
  padding: 20px;

  /* Simple animation for the modal content */
  animation: fadeInScale 0.3s ease forwards;
  transform-origin: center;
}

@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(0.85);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Font Awesome Icon styling */
.enhanced-modal-icon-fa {
  color: var(--main-color);
  font-size: 64px;
  margin-bottom: 20px;
}

/* Message text */
.password-recovery-message {
  margin-top: 1rem;
  font-size: 1rem;
  line-height: 1.4;
}
