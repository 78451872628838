.calendar-page-container {
  background-color: #fff;
  padding: 20px;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  max-width: var(--max-width);
  margin: 20px auto;
  color: var(--main-color);
  position: relative;
}

/* Header Container */
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* push h2 to the left, button(s) to the right */
  margin-bottom: 20px;
}

.header-container h2 {
  flex: 1;
  text-align: center;
  margin: 0;
  color: var(--main-color);
  font-size: 2rem;
  font-weight: var(--font-weight-bold);
}

.config-button {
  position: relative;
}

.menu-container {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid var(--main-color);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  z-index: 1000;
  width: 200px;
}

.dropdown-menu button {
  width: 100%;
  background: none;
  border: none;
  padding: 10px;
  text-align: left;
  color: var(--main-color);
  font-size: 1rem;
  cursor: pointer;
}

.dropdown-menu button:hover {
  background-color: var(--hover-background-color);
}

.menu-icon {
  margin-right: 10px;
}

/* Calendar Navigation */
.calendar-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.nav-button {
  background-color: var(--main-color);
  border: none;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 10px;
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
}

.nav-button:hover {
  color: var(--main-color);
  background-color: #fff;
  border: 2px solid var(--main-color);
  padding: 6px;
}

.current-month {
  font-size: 1.5rem;
  font-weight: var(--font-weight-bold);
  color: var(--main-color);
}

/* Calendar Table */
.calendar-table-container {
  overflow-x: auto;
}

.calendar-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.calendar-table th,
.calendar-table td {
  border: 1px solid var(--main-color);
  width: 14.28%;
  vertical-align: top;
  position: relative;
}

.calendar-table th {
  background-color: #fff;
  text-align: center;
  color: var(--main-color);
  padding: 10px 0;
  font-weight: var(--font-weight-bold);
  height: 40px;
}

.calendar-table td {
  padding: 5px;
  cursor: pointer;
  color: var(--main-color);
  height: 100px;
}

.calendar-table td:hover {
  background-color: var(--hover-background-color);
}

.date-number {
  font-weight: var(--font-weight-bold);
  margin-bottom: 5px;
  color: var(--main-color);
  font-size: 1.2rem;
}

.event {
  margin-bottom: 5px;
  padding: 5px;
  border-radius: var(--border-radius);
  font-size: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.event:hover {
  background-color: var(--primary-hover-color);
}

.event.extra-count {
  background-color: #ccc;
  color: #000;
}

.current-month {
  background-color: #fff;
}

.other-month {
  background-color: #f9f9f9;
  color: var(--secondary-text-color);
}

.today {
  border: 2px solid var(--main-color);
}

/* Event Bottom Bar */
.event-bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border-top: 1px solid var(--main-color);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transform: translateY(100%);
  animation: slideUp 0.3s ease-out forwards;
}

.event-bottom-bar-content {
  padding: 20px;
  max-width: var(--max-width);
  margin: 0 auto;
  position: relative;
}

.event-bottom-bar-content h3 {
  margin-top: 0;
  color: var(--main-color);
}

.event-list {
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 20px;
  scrollbar-width: thin;
  scrollbar-color: var(--main-color) #f0f0f0;
}

.event-list::-webkit-scrollbar {
  width: 6px;
  background-color: #f0f0f0;
}

.event-list::-webkit-scrollbar-thumb {
  background-color: var(--main-color);
  border-radius: 3px;
}

.event-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 0;
  border-bottom: 1px solid var(--main-color);
}

.event-row.last {
  border-bottom: none;
}

.event-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.event-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.item-icon {
  font-size: 1.2rem;
}

.item-type-label {
  font-weight: var(--font-weight-bold);
  font-size: 0.95rem;
  color: var(--main-color);
}

.event-name {
  font-weight: var(--font-weight-bold);
  color: var(--main-color);
  font-size: 1rem;
}

.event-time,
.event-location,
.event-description {
  font-size: 0.9rem;
  color: var(--secondary-text-color);
}

.edit-button {
  background-color: var(--main-color);
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: var(--border-radius);
  cursor: pointer;
  margin-left: 10px;
  align-self: center;
}

.edit-button:hover {
  background-color: #fff;
  color: var(--main-color);
  border: 2px solid var(--main-color);
  padding: 3px 8px;
}

.add-event-button {
  background-color: var(--main-color);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: var(--border-radius);
  cursor: pointer;
}

.add-event-button:hover {
  background-color: #fff;
  border: 2px solid var(--main-color);
  padding: 8px 18px;
  color: var(--main-color);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.error-message {
  text-align: center;
  color: red;
  font-weight: var(--font-weight-bold);
}

.past-event {
  opacity: 0.4;
}

.filters-container {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .calendar-table th,
  .calendar-table td {
    height: 60px;
  }

  .date-number {
    font-size: 1rem;
  }

  .event {
    font-size: 0.8rem;
  }

  .nav-button {
    padding: 8px;
    font-size: 1rem;
  }

  .nav-button:hover {
    color: var(--main-color);
    background-color: #fff;
    border: 2px solid var(--main-color);
    padding: 6px;
  }

  .current-month {
    font-size: 1.2rem;
  }

  .header-container {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .config-button {
 margin-top: 0;
  }

  .event-bottom-bar-content {
    padding: 15px;
  }

  .filters-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}

@media (max-width: 480px) {
  .calendar-table th,
  .calendar-table td {
    height: 50px;
  }

  .date-number {
    font-size: 0.9rem;
  }

  .event {
    font-size: 0.7rem;
  }

  .nav-button {
    padding: 6px;
    font-size: 0.9rem;
  }

  .current-month {
    font-size: 1rem;
  }

  .config-button {
    margin-top: 10px;
  }

  .event-bottom-bar-content {
    padding: 10px;
  }

  .edit-button {
    padding: 4px 8px;
    font-size: 0.8rem;
  }

  .edit-button:hover {
    color: #fff;
    background-color: var(--main-color);
  }

  .add-event-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .close-button {
    font-size: 1rem;
  }
}
