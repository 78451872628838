/* src/components/FormBuilder/FillOutForm.css */

.fill-out-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.fill-out-form h2 {
  margin-bottom: 20px;
  color: #343a40;
}

.fill-out-form .submit-button {
  align-self: stretch; /* Ensures the button spans full width */
}

.fill-out-form .full-width-button {
  width: 100%;
}

.fill-out-form input:required,
.fill-out-form select:required,
.fill-out-form textarea:required {
  border-left: 4px solid #dc3545;
}

/* Optional: Add some spacing or styling for error messages if implemented */
.fill-out-form .error-message {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: -2px;
}

/* New Styles for Row Layout */
.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.form-field {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.form-field.half-width {
  flex: 1 1 48%; /* Approximately half the row, accounting for gap */
}

.form-field.full-width {
  flex: 1 1 100%;
}

/* Ensure fields take full width within their container */
.form-field > * {
  width: 100%;
  box-sizing: border-box;
}

/* Responsive Design */
@media (max-width: 768px) {
  .fill-out-form {
    padding: 15px;
  }

  .fill-out-form .submit-button {
    align-self: stretch;
  }

  .fill-out-form .full-width-button {
    width: 100%;
  }

  /* Adjust row layout for smaller screens */
  .form-row {
    flex-direction: column;
  }

  .form-field.half-width {
    flex: 1 1 100%; /* Stack fields vertically on small screens */
  }
}

/* New Styles to Center Spinner */
.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px; /* Adjust as needed */
}

.form-actions
{
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

.consent-section.consent-error {
  border: 2px solid #dc3545;
  animation: shake 0.3s ease-in-out;
  padding: 10px; /* Ensure there is enough space for the border */
  border-radius: 4px;
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}