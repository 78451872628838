/* Overall layout */
.login-split-container {
    display: flex;
    min-height: 100vh;
    width: 100%;
    background: var(--main-color);
  }
  
  /* LEFT PANEL */
  .login-left-panel {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #fff;
    position: relative;
    padding: 20px;
  }
  
  .login-left-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 400px;
    margin: 0 auto;
    width: 100%;
  }
  
  /* Top-left logo */
  .login-left-logo {
    position: absolute;
    top: 20px;
    left: 20px;
  }
  
  .login-left-logo img {
    width: 12vw;
    max-width: 200px;
    height: auto;
  }
  
  .login-center-content {
    text-align: center;
  }
  
  /* Center logo */
  .login-logo img {
    width: 15vw;
    max-width: 600px;
    margin-bottom: 20px;
  }
  
  /* Desktop footer */
  .login-footer.desktop-footer {
    text-align: center;
    padding: 10px 0;
  }
  
  /* Desktop policy links */
  .login-policy-links {
    text-align: center;
  }
  
  .login-policy-link {
    color: var(--main-color);
    font-weight: bold;
    text-decoration: none;
    margin: 0 10px;
  }
  
  .login-policy-separator {
    color: var(--text-color);
  }
  
  /* RIGHT PANEL */
  .login-right-panel {
    flex: 1;
    background: var(--split-divider-color);
    position: relative;
    display: flex;
    flex-direction: column; /* so we can place the verse and footer stacked */
    justify-content: center;
    align-items: center;
  }
  
  /* Mobile/Tablet footer in the right panel (hidden on desktop) */
  .login-footer-mobile {
    display: none; /* hidden by default on desktop */
    text-align: center;
    padding: 10px 0;
  }
  
  /* Mobile policy links */
  .login-policy-links-mobile {
    text-align: center;
  }
  
  .login-policy-link-mobile {
    color: #fff; /* white text on mobile/tablet */
    font-weight: bold;
    text-decoration: none;
    margin: 0 10px;
  }
  
  .login-policy-separator-mobile {
    color: #fff;
  }
  
  /* ================ RESPONSIVE BREAKPOINTS ================ */
  
  /* On screens <= 992px (tablet and below), stack panels and show mobile footer */
  @media (max-width: 992px) {

    
    .login-split-container {
      flex-direction: column;
    }

    .login-left-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        width: 100%;
      }
  
    .login-left-panel,
    .login-right-panel {
      width: 100%;
      flex: none;
    }
  
    .login-right-panel {
      padding: 20px;
      height: auto;
    }

    .login-left-logo img {
        width: 22vw;
        max-width: none;
      }

      .login-logo img {
        width: 25vw;
        max-width: none;
      }
  
    .login-left-logo {
      position: static;
      margin-bottom: 20px;
    }
  
    /* Hide the desktop footer on mobile/tablet */
    .desktop-footer {
      display: none;
    }
  
    /* Show the mobile footer in the right panel */
    .login-footer-mobile {
      display: block;
      margin-top: auto; /* push it to the bottom, if desired */
    }
  }
  
  /* Further refinement on very small screens if needed */
  @media (max-width: 600px) {
    .login-left-logo img {
        width: 25vw;
        max-width: 200px;
        height: auto;
    }
  
    .login-logo img {
        width: 40vw;
        max-width: none;
    }
  }
  