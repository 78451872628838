/* Root variables */
:root {
  --main-color: #bb8300;
  --secondary-color: #31423c;
  --input-border-color: #ddd;
  --button-bg-color: var(--main-color);
  --button-hover-bg-color: var(--secondary-color);
  --text-color: #fff;
}

/* Container to constrain the form width */
.step3-container {
  width: 100%;
  max-width: 500px; /* Adjust this value as needed */
  margin: 0 auto;   /* Center the container */
}

/* Basic form container */
.sign-up-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
  margin-top: 20px;
}

/* Input group styles */
.sign-up-input-group {
  margin-bottom: 15px;
  text-align: left;
}

.sign-up-input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: var(--main-color);
}

.sign-up-input-group input {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid var(--input-border-color);
  border-radius: 5px;
  font-size: 1em;
  background-color: #fff;
  box-sizing: border-box;
}

/* Password field & toggle */
.password-field {
  position: relative;
}

.password-field input {
  padding-right: 40px;
}

.password-toggle-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #666;
}

/* Password strength bars */
.password-strength-bars {
  display: flex;
  margin-top: 5px;
}

.password-strength-bars .bar {
  flex: 1;
  height: 5px;
  margin-right: 2px;
  background-color: #e0e0e0;
}

.password-strength-bars .bar:last-child {
  margin-right: 0;
}

.password-strength-bars .bar.weak {
  background-color: red;
}

.password-strength-bars .bar.medium {
  background-color: orange;
}

.password-strength-bars .bar.strong {
  background-color: green;
}

.password-strength-text {
  font-size: 0.85em;
  margin-top: 5px;
}

.password-strength-text.weak {
  color: red;
}

.password-strength-text.medium {
  color: orange;
}

.password-strength-text.strong {
  color: green;
}

/* Primary and Back buttons */
.sign-up-submit-button {
  width: 100%;
  padding: 12px 15px;
  border: none;
  border-radius: 5px;
  background-color: var(--button-bg-color);
  color: #fff;
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.sign-up-submit-button:hover {
  background-color: var(--button-hover-bg-color);
}

/* Back button styles (if needed) */
.sign-up-back-button {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid var(--main-color);
  border-radius: 5px;
  background-color: #fff;
  color: var(--main-color);
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.sign-up-back-button:hover {
  background-color: var(--main-color);
  color: #fff;
}

/* Error message styles */
.error-message {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(255, 0, 0, 0.1);
  color: rgb(96, 8, 8);
  margin-top: 5px;
}

.error-icon {
  margin-right: 8px;
  font-size: 1.2em;
}

/* Success message styles (if applicable) */
.success-message {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(0, 128, 0, 0.1);
  color: var(--main-color);
  margin-bottom: 10px;
}

.success-icon {
  margin-right: 8px;
  font-size: 1.2em;
}
