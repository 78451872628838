/* src/components/Settings/ColorPickerGroup.css */

/* Form Group for Color Picker */
.color-picker-group {
    flex: 0.5;
    min-width: 200px;
    display: flex;
    flex-direction: column;
  }
  
  /* Labels */
  .form-group label {
    font-weight: var(--font-weight-medium);
    margin-bottom: 10px;
    font-size: 1rem;
  }
  
  /* Primary Color Picker */
  #primaryColor {
    width: 100%;
    height: 40px;
    border: none;
    cursor: pointer;
    padding: 0;
    border-radius: var(--border-radius);
    background-color: var(--background-color);
    outline: none;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .color-picker-group {
      flex: 1 1 100%;
      min-width: 100%;
    }
  }
  