/* src/components/TabNavigation.css */

.tab-navigation {
  background-color: var(--main-color);
  padding: 10px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.tab-navigation button {
  margin: 5px 10px;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  font-size: 1em;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, opacity 0.3s ease;
}

.tab-navigation button:hover:not(:disabled) {
  background-color: var(--main-color);
}

.active-tab {
  background-color: #fff !important;
  color: var(--main-color) !important;
}

.tab-navigation button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

@media (max-width: 768px) {
  .tab-navigation button {
    margin: 5px;
    padding: 8px 16px;
  }
}
