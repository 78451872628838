/* src/components/Common/ToggleSwitch.css */

.toggle-switch-container {
  display: flex;
  align-items: center;
}

.toggle-switch-container.label-left .toggle-switch-label {
  margin-right: 8px; /* Space between label and toggle */
}

.toggle-switch-container.label-right .toggle-switch-label {
  margin-left: 8px; /* Space between toggle and label */
}

.toggle-switch-label {
  font-size: 1rem;
  color: var(--main-color);
  font-weight: 600;
  padding-bottom: 7px;
  font-family: var(--font-family)
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  background-color: #ccc; /* Default color when not checked */
  border-radius: 34px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: background-color 0.4s;
}

.slider::before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.4s;
}

.toggle-switch input:checked + .slider {
  /* The background color is set inline via the style prop in ToggleSwitch.jsx */
}

.toggle-switch input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

.toggle-switch input:checked + .slider::before {
  transform: translateX(26px);
}
