/* src/components/FormBuilder/ResponsesTab.css */

.responses-tab-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  min-height: 400px; /* Add minimum height to ensure vertical centering has space */
}

.top-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px; /* Space between Total Responses and Export button */
}

.responses-count {
  font-size: 1rem; /* Standard font size */
  font-weight: 600;
  color: #343a40;
}

.count-number {
  color: var(--main-color); /* Highlight the count number */
}

.export-csv-container {
  /* Removed since export button is now part of top-actions */
}

.export-csv-button {
  /* Additional styling if needed */
}

.responses-list {
  display: flex;
  flex-direction: column;
  gap: 12px; /* Reduced gap between response cards */
}

/* Response Card Styles */
.response-card {
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 6px; /* Slightly smaller border radius */
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  transition: box-shadow 0.2s ease, transform 0.2s ease;
}

.response-card:hover {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(-1px);
}

/* Response Header */
.response-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 14px; /* Reduced padding */
  background-color: #f8f9fa;
  cursor: pointer;
  user-select: none;
}

.response-header:focus {
  outline: 2px solid #007bff;
}

.response-header-content {
  display: flex;
  flex-direction: column;
}

.response-submitted-at {
  font-size: 0.875rem; /* Smaller font size */
  font-weight: 600;
  color: #343a40;
}

/* Header Buttons */
.response-header-buttons {
  display: flex;
  align-items: center;
  gap: 6px; /* Reduced gap between buttons */
}

/* Delete Button */
.delete-button {
  background: transparent;
  border: none;
  color: #dc3545; /* Bootstrap danger color */
  cursor: pointer;
  font-size: 0.875rem; /* Smaller font size */
  padding: 4px;
  transition: color 0.3s ease;
}

.delete-button:hover {
  color: #a71d2a;
  background-color: #e0e0e0;
}

.delete-button:focus {
  outline: 2px solid #dc3545;
  border-radius: 4px;
}

.pdf-button
{
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.pdf-button:hover {
  background-color: #e0e0e0;
}

/* Toggle Icon */
.response-toggle-icon {
  font-size: 1rem; /* Smaller font size */
  color: #343a40;
  transition: transform 0.3s ease;
}

.response-toggle-icon svg {
  width: 0.8rem; /* Smaller icon size */
  height: 0.8rem;
}

/* Response Details */
.response-details {
  padding: 10px 14px; /* Reduced padding */
  background-color: #ffffff;
}

.response-table {
  width: 100%;
  border-collapse: collapse;
}

.response-table th,
.response-table td {
  text-align: left;
  padding: 8px; /* Reduced padding */
  border-bottom: 1px solid #dee2e6;
  color: #495057;
  font-size: 0.85rem; /* Smaller font size */
}

.response-table th {
  background-color: #f1f3f5;
  font-weight: 600;
  color: #495057;
}

.response-table tr:last-child td {
  border-bottom: none;
}

.response-image {
  max-width: 160px; /* Reduced max width */
  max-height: 160px; /* Reduced max height */
  object-fit: contain;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: var(--main-color);
}

/* Animations for Response Cards */
.response-enter {
  opacity: 0;
  transform: scale(0.95);
}

.response-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 200ms, transform 200ms;
}

.response-exit {
  opacity: 1;
  transform: scale(1);
}

.response-exit-active {
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 200ms, transform 200ms;
}

/* Animations for Collapse */
.collapse-enter {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

.collapse-enter-active {
  max-height: 400px; /* Adjust as needed */
  opacity: 1;
  transition: max-height 200ms ease-in-out, opacity 200ms ease-in-out;
}

.collapse-exit {
  max-height: 400px;
  opacity: 1;
}

.collapse-exit-active {
  max-height: 0;
  opacity: 0;
  transition: max-height 200ms ease-in-out, opacity 200ms ease-in-out;
}

/* No Responses Text */
.no-responses {
  font-size: 0.9rem;
  color: #6c757d;
  text-align: center;
  margin: auto; /* Centers vertically when used with flex container */
  padding: 20px;
}

.action-buttons {
  display: flex;
  gap: 8px; /* Space between Export and Import buttons */
}

/* Importing Overlay */
.importing-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.importing-text {
  margin-top: 16px;
  font-size: 1.2rem;
  color: #333;
}

.edit-button,
.delete-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.edit-button:hover {
  background-color: #e0e0e0;
}

.multi-row-control-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 8px;
}

.multi-row-control-table th,
.multi-row-control-table td {
  border: 1px solid #dee2e6;
  padding: 6px;
  text-align: left;
  font-size: 0.85rem;
}

.multi-row-control-table th {
  background-color: #f1f3f5;
  font-weight: 600;
}

/* Responsive Design */

@media (max-width: 768px) {
  .responses-tab-container {
    padding: 12px;
    min-height: 300px; /* Slightly smaller minimum height for mobile */
  }

  .top-actions {
    gap: 12px;
  }

  .responses-count {
    font-size: 0.9rem;
  }

  .count-number {
    font-size: 0.9rem;
  }

  .export-csv-button {
    /* Adjust button size for mobile if necessary */
  }

  .responses-list {
    gap: 10px;
  }

  .response-header {
    padding: 8px 12px;
  }

  .response-submitted-at {
    font-size: 0.8rem;
  }

  .response-header-buttons {
    gap: 4px;
  }

  .delete-button {
    font-size: 0.75rem;
    padding: 3px;
  }

  .response-toggle-icon {
    font-size: 0.9rem;
  }

  .response-toggle-icon svg {
    width: 0.7rem;
    height: 0.7rem;
  }

  .response-details {
    padding: 8px 12px;
  }

  .response-table th,
  .response-table td {
    padding: 6px;
    font-size: 0.8rem;
  }

  .response-image {
    max-width: 140px;
    max-height: 140px;
  }
}
