/* src/components/FormBuilder/Fields/ColorPicker.css */

/* Form Group for Color Picker */
.color-picker-group {
    flex: 0.5;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px; /* Added margin for spacing */
  }
  
  /* Labels */
  .color-picker-group label {
    font-weight: var(--font-weight-medium);
    font-size: 1rem;
    display: block;
    font-weight: 600;
    margin-bottom: 5px;
  }
  
  /* Color Picker Input */
  .color-picker-group input[type="color"] {
    width: 100%;
    height: 40px;
    border: none;
    cursor: pointer;
    padding: 0;
    border-radius: var(--border-radius);
    background-color: var(--background-color);
    outline: none;
  }
  
  /* Required Asterisk */
  .color-picker-group .required-asterisk {
    color: red;
  }
  
  /* Error Message */
  .color-picker-group .error-message {
    color: red;
    font-size: 0.875rem;
    margin-top: 5px;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .color-picker-group {
      flex: 1 1 100%;
      min-width: 100%;
    }
  }
  