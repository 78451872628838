/* src/components/GroupTable/GroupsTable.css */

/* Define CSS variables or import from a global file if needed */
:root {
  --main-color: #bb8300; 
  --secondary-color: #31423c;
  --background-color: #f5f5f5;
  --font-family: 'Arial', sans-serif;
  --font-size: 1em;
  --padding: 20px;
  --table-header-bg: var(--main-color);
  --table-header-text-color: #ffffff;
  --table-row-bg-alt: #f3f3f3;
  --table-border-color: #dddddd;
  --button-bg-color: var(--main-color);
  --button-text-color: #ffffff;
  --button-hover-bg-color: var(--main-color);
  --input-border-color: #ced4da;
}

/* Container for the entire Groups table component */
.groups-table-container {
  padding: 10px 0; /* Similar spacing used in TeamsTable */
  position: relative;
}

/* Single flex row for SearchBar & Add button */
.groups-header-actions {
  display: flex;
  align-items: center;
  gap: 10px;          /* spacing between search and button */
  margin-bottom: 10px;
}

/* Record count styling */
.record-count {
  font-size: 16px;
  color: var(--main-color);
  text-align: center; /* or left, your preference */
  margin-bottom: 10px;
}

/* Enhanced Search Bar */
.search-bar {
  flex: 1;
  padding: 12px 16px;
  font-size: 1.1em;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.search-bar:focus {
  border-color: var(--main-color);
  background-color: #f9f9f9;
}

.search-bar.typing {
  background-color: #e9ecef;
}

/* Action Button */
.action-button {
  background-color: var(--main-color) !important;
  color: var(--button-text-color) !important;
  border: none !important;
  padding: 10px 20px !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  font-size: 1em !important;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.action-button:hover {
  background-color: var(--main-color) !important;
  transform: translateY(-2px);
}

/* Table styling (if you have a .group-table class or rely on a generic .table) */
/* For consistency, you might use .group-table or reuse the same .member-table/.teams-table classes. 
   If you have a Common/Table component styling, that might be separate. */

/* Pagination controls, if needed, copy from your existing approach. 
   If your Table or PaginationControls component has separate styling, 
   no further changes needed in this file. */

/* Example pagination style (if relevant):
.pagination-container {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}
*/
