/* src/components/ToastContainer.css */

.toast-container {
    position: fixed;
    bottom: 20px; /* Position 20px from the bottom */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Center the container */
    display: flex;
    flex-direction: column-reverse; /* New toasts appear at the bottom */
    align-items: center;
    z-index: 1000; /* Ensure the Toasts appear above other elements */
    
    /* Allow pointer events to pass through the container */
    pointer-events: none;
  }
  
  .toast-container .toast {
    /* Ensure that individual toasts can capture pointer events */
    pointer-events: auto;
    
    /* Optional: Add spacing between toasts */
    margin-top: 10px;
    
    /* Additional styles for better appearance */
    min-width: 250px;
    padding: 16px 24px;
    border-radius: 8px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    
    /* Initial state for animations */
    opacity: 0;
    animation: fadeInOut 5000ms forwards; /* Duration should match the Toast duration */
  }
  
  /* Combined Fade In and Fade Out Animation for Toasts */
  @keyframes fadeInOut {
    0% {
      opacity: 0;
      transform: translateY(20px); /* Start slightly below */
    }
    10% {
      opacity: 1;
      transform: translateY(0); /* Slide up to original position */
    }
    90% {
      opacity: 1;
      transform: translateY(0); /* Remain visible */
    }
    100% {
      opacity: 0;
      transform: translateY(20px); /* Slide back down */
    }
  }
  
  /* Optional: Close Button Styling */
  .toast-close-button {
    background: transparent;
    border: none;
    color: #fff;
    font-size: 1.2em;
    cursor: pointer;
    line-height: 1;
  }
  
  .toast-content {
    display: flex;
    align-items: center;
  }
  
  .toast-icon {
    margin-right: 8px;
    font-size: 1.2em;
  }
  