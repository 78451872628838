/* src/components/FormBuilder/PublicForm.css */

.public-form-background {
  width: 100%;
  min-height: 100vh; /* Adjusted to full viewport height */
  background-color: var(--main-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  position: relative; /* Allows absolute positioning of Catholicore logo */
}

/* Catholicore Logo in Top-Left */
.catholicore-logo-container {
  position: absolute;
  top: 20px;
  left: 20px;
}

.catholicore-logo {
  width: 120px;
  height: auto;
  cursor: pointer;
}

.public-form-container {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}

.public-form-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #fff;
}

/* Optional extra logo (if provided by group) */
.logo-container {
  text-align: center;
  margin-bottom: 20px;
}

.form-logo {
  max-width: 150px;
  height: auto;
}

.form-closed-message {
  text-align: center;
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  border-radius: 8px;
}

.form-closed-message p {
  font-size: 1.2rem;
  color: #ffffff;
}

/* New Styles for Form Not Found */
.form-not-found {
  text-align: center;
  font-size: 1.5rem;
  color: #dc3545; /* Bootstrap danger color for emphasis */
  padding: 40px;
}

.form-submitted-message {
  text-align: center;
  margin: 20px 0;
}

.form-submitted-message p {
  font-size: 1.2em;
  color: #fff;
}

/* Advertisement Section */
.advertisement {
  margin-top: 30px;
  text-align: center;
}

.advertisement-logo {
  width: 200px;
  height: auto;
  margin-bottom: 15px;
}

.advertisement-text {
  font-size: 1.1rem;
  color: #ffffff;
  margin-bottom: 15px;
}

.advertisement-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ffffff;
  color: var(--main-color);
  border: none;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
}

.advertisement-button:hover {
  background-color: #ccc;
}
