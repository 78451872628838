/* src/components/Main/Header.css */

.app-header {
  background-color: var(--main-color);
  border-bottom: 1px solid #ddd;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
}

.header-white-logo {
  position: absolute;
  top: 10px;
  left: 20px;
}

.header-white-logo img {
  width: 150px; /* Adjust size as needed */
  height: auto;
}

.header-main-content {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: var(--max-width);
  justify-content: space-between;
  flex-wrap: wrap;
}

.organization-logo {
  /* Add styles for organization logo if needed */
  margin: 0 20px;
}

@media (max-width: 768px) {
  .header-white-logo img {
    width: 110px;
  }

  .app-header {
    padding: 8px 15px;
    height: auto; /* Remove fixed height to allow content to expand */
  }

  .header-main-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .organization-logo {
    margin: 10px 0;
  }

  /* Ensure TabNavigation spans full width */
  .tab-navigation {
    width: 100%;
    overflow-x: auto;
  }

  /* Hide scrollbars while allowing scrolling */
  .tab-navigation::-webkit-scrollbar {
    display: none;
  }

  .tab-navigation {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
