/* src/components/MessageSender/ImageUpload.css */

/* Form Group for Image Upload */
.image-upload-group {
    flex: 1;
    min-width: 250px; /* Ensures minimum width */
    display: flex;
    flex-direction: column;
  }
  
  /* Labels */
  .form-group label {
    font-weight: var(--font-weight-medium);
    margin-bottom: 8px; /* Reduced margin */
    font-size: 1rem;
  }
  
  /* Dropzone Styles */
  .dropzone {
    box-sizing: border-box;
    border: 2px dashed var(--main-color);
    border-radius: var(--border-radius);
    padding: 12px; /* Reduced padding */
    margin-bottom: 10px;
    text-align: center;
    background-color: var(--background-color);
    transition: border-color 0.3s ease, background-color 0.3s ease;
    cursor: pointer;
    height: 100px; /* Fixed height for consistency */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .dropzone:hover,
  .dropzone.is-drag-active {
    border-color: var(--secondary-color);
    background-color: var(--hover-bg-color);
  }
  
  /* Paragraph inside Dropzone */
  .dropzone p {
    font-size: 0.95rem;
    color: var(--dropzone-text-color) !important;
    font-weight: var(--font-weight-medium);
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .dropzone p svg {
    margin-right: 8px; /* Space between icon and text */
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .image-upload-group {
      flex: 1 1 100%;
      min-width: 100%;
    }
  
    .dropzone {
      padding: 10px;
      height: 80px;
    }
  }
  