/* src/components/Authentication/Login.css */

/* Form styling */

.sign-in-title
{
margin-bottom: 10px;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.login-input-group input {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
}

/* Submit button */
.login-submit-button {
  width: 100%;
  padding: 12px 15px;
  border: none;
  border-radius: 5px;
  background-color: var(--main-color);
  color: #fff;
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.login-submit-button:hover {
  background-color: var(--secondary-color);
  color: #fff;
}

/* Links below the form */
.login-links-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 1rem;
}

.link-button {
  background-color: #fff;
  color: var(--main-color);
  border: 1px solid var(--main-color);
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 0.95rem;
  font-weight: 600;
  text-align: center;
  transition: background-color 0.3s ease;
}

.link-button:hover {
  background-color: var(--main-color);
  color: #fff;
}

.login-error {
  color: #8B0000; /* Dark Red */
  margin-top: 0.5rem;
  font-weight: 600;
}
