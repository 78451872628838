/* src/components/FormBuilder/FieldSelector.css */

.field-selector {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.field-selector-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  color: #000;
}

.full-width {
  width: 100%;
}

.full-width-input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 1rem;
  color: #495057;
}

.required-checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: normal;
  color: #495057;
}

.required-checkbox input[type="checkbox"] {
  accent-color: #007bff;
}

.size-options {
  display: flex;
  gap: 20px;
  margin-top: 5px;
}

.size-options label {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: normal;
  color: #495057;
}

.size-options input[type="radio"] {
  accent-color: #007bff;
}

.field-selector-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.full-width-button {
  width: 100%;
}

/* Option Rows */
.option-row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.option-input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1rem;
  color: #495057;
}

.label-input {
  /* Specific styles for label input if needed */
}

.value-input {
  /* Styles for the read-only value input */
  background-color: #e9ecef;
  cursor: not-allowed;
}

.remove-option-button {
  background-color: transparent;
  border: none;
  color: red;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0;
  line-height: 1;
}

/* Add Option Button Container */
.add-option-container {
  margin-top: 10px;
}

/* Field Type Buttons Grid */
.field-type-buttons {
  display: grid;
  gap: 8px; /* Space between buttons */
}

/* For Full Width: 2 rows of 8 columns */
.field-type-buttons.full {
  grid-template-columns: repeat(8, 1fr);
}

/* For Half Width: 4 rows of 4 columns */
.field-type-buttons.half {
  grid-template-columns: repeat(4, 1fr);
}

/* Ensure on mobile, it switches to 4 columns */
@media (max-width: 768px) {
  .field-type-buttons.full,
  .field-type-buttons.half {
    grid-template-columns: repeat(4, 1fr);
  }

  .size-options {
    flex-direction: column;
  }

  .option-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .remove-option-button {
    align-self: flex-end;
  }
}

/* Highlight selected Field Type button */
.field-type-button.selected {
  border: 2px solid #007bff;
}

/* Ensure ActionButton's full-width-button works correctly */
.full-width-button.action-button {
  justify-content: center; /* Center the icon and text */
  padding: 12px; /* Increase padding for better appearance */
  font-size: 1rem; /* Ensure readable text */
  height: 50px; /* Example height */
}

/* Adjust the size of icon and text within full-width buttons */
.full-width-button .button-icon {
  width: 18px;
  height: 18px;
}

.full-width-button .button-text {
  font-size: 1rem;
}

/* Add Option Button Styling (optional) */
.add-option-button {
  /* Customize as needed */
}

/* Styles for the Option Sources Toggle */
.options-source-toggle {
  display: flex;
  align-items: center;
  gap: 20px; /* Space between each radio option */
  margin-top: 5px;
}

.radio-option {
  display: flex;
  align-items: center;
  gap: 8px; /* Space between radio button and label */
  font-size: 0.95rem;
  color: #495057;
  cursor: pointer;
}

.radio-option input[type="radio"] {
  width: 16px;
  height: 16px;
  accent-color: #007bff; /* Customize the radio button color */
}

/* Optional: Highlight the label when the radio button is checked */
.radio-option input[type="radio"]:checked + span {
  font-weight: bold;
  color: #007bff;
}