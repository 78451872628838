/* src/components/SecurityPage.css */

/* Overall container styling */
.security-page-container {
  width: 100%;
  padding: 20px;
  background-color: #fff;
}

/* Top tabs for Users / Roles */
.security-page-tabs {
  display: flex;
  border-bottom: 2px solid #ddd;
  margin-bottom: 20px;
}

.security-tab-button {
  background: none;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  color: #555;
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s ease, color 0.3s ease;
}

.security-tab-button:hover {
  color: #000;
}

.security-tab-button.active {
  border-bottom: 2px solid var(--main-color);
  color: var(--main-color);
  font-weight: bold;
}

.security-page-content {
  margin-top: 20px;
}

/* Left panel, similar style to ProfilePage’s groups list */
.groups-section {
  width: 100%;
  margin-top: 0.5rem;
}

.groups-section h4 {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  text-align: left;
}

.left-groups-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.group-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-radius: 4px;
  transition: background-color 0.2s, color 0.2s;
  color: #333; /* default text color */
}

.group-item:hover {
  background-color: #f3f3f3;
}

/* Highlight the selected group with main color + white text */
.group-item.selected {
  background-color: var(--main-color);
  color: #fff;
}

/* Group thumbnail (similar to the ProfilePage style) */
.group-thumbnail {
  width: 40px;
  height: 40px;
  border-radius: 200px;
  object-fit: cover;
  border: 1px solid #ccc;
}

/* Right panel heading, if you have one */
.security-right-panel-content h3 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

/* A simple container if no group is selected (if you need it) */
.no-group-selected {
  padding: 2rem;
  text-align: center;
  color: #777;
}

/* Loading container */
.loading-container {
  text-align: center;
  padding: 2rem;
  color: #333;
}

/* Access denied container */
.access-denied {
  color: #c00;
  font-weight: bold;
  text-align: center;
  padding: 2rem;
}
