/* src/components/Common/Spinner.css */

/* Custom Spinner */
.custom-spinner {
  width: 50px; /* Default size, can be overridden via inline styles */
  height: 50px; /* Default size, can be overridden via inline styles */
  border: 8px solid rgba(0, 0, 0, 0.1); /* Light gray background */
  border-top: 8px solid var(--main-color); /* Will be overridden by inline styles */
  border-radius: 50%;
  animation: spin 1s linear infinite;
  /* Remove any absolute positioning */
  /* position: absolute; */
  /* Ensure the spinner is displayed inline */
  display: inline-block;
}


/* Spinner Animation */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
