/* src/components/Settings/LogoUploadGroup.css */

/* Form Group for Logo Upload */
.logo-upload-group {
    flex: 1;
    min-width: 280px;
    display: flex;
    flex-direction: column;
  }
  
  /* Labels */
  .form-group label {
    font-weight: var(--font-weight-medium);
    margin-bottom: 10px;
    font-size: 1rem;
  }
  
  /* Dropzone Styles */
  .dropzone {
    box-sizing: border-box;
    border: 2px dashed var(--main-color);
    border-radius: var(--border-radius);
    padding: 15px;
    margin-bottom: 10px;
    text-align: center;
    background-color: var(--background-color);
    transition: border-color 0.3s ease, background-color 0.3s ease;
    cursor: pointer;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .dropzone:hover,
  .dropzone.is-drag-active {
    border-color: var(--secondary-color);
    background-color: var(--hover-bg-color);
  }
  
  /* Paragraph inside Dropzone */
  .dropzone p {
    font-size: 1rem;
    color: var(--text-color);
    font-weight: var(--font-weight-medium);
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .dropzone p svg {
    margin-right: 8px;
  }
  
  /* Logo Preview */
  .logo-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    background-color: var(--background-color);
    padding: 10px;
    border-radius: var(--border-radius);
  }
  
  .logo-preview img {
    max-width: 150px;
    max-height: 150px;
    border-radius: var(--border-radius);
    border: 1px solid var(--border-color);
    object-fit: contain;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .logo-upload-group {
      flex: 1 1 100%;
      min-width: 100%;
    }
  
    .logo-preview img {
      max-width: 120px;
      max-height: 120px;
    }
  
    .dropzone {
      padding: 12px;
      height: 100px;
    }
  }
  