/* src/components/Common/PaginationControls.css */

.pagination-controls-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
  flex-wrap: wrap;
}

.pagination-options {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 10px;
}

.pagination-options label {
  margin-right: 10px;
  font-weight: bold;
}

.pagination-options select {
  padding: 8px 10px;
  border-radius: 6px;
  border: 1px solid var(--input-border-color);
  font-size: 1em;
  transition: border-color 0.3s ease;
}

.pagination-options select:focus {
  border-color: var(--main-color);
  outline: none;
}

.pagination-controls {
  display: flex;
  align-items: center;
}

.pagination-controls button {
  margin: 5px 5px;
  background-color: var(--main-color);
  color: var(--button-text-color);
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.pagination-controls button:hover {
  background-color: var(--main-color);
  transform: translateY(-1px);
}

.pagination-controls button[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}

.selected-page-button {
  background-color: var(--main-color) !important;
  color: #ffffff !important;
  border-color: var(--main-color) !important;
}

.page-button {
  background-color: #333 !important;
  color: #fff !important;
  border-color: var(--main-color) !important;
}

.page-button:hover {
  background-color: var(--main-color) !important;
  color: #fff !important;
}

@media (max-width: 768px) {
  .pagination-controls-container {
    flex-direction: column;
    align-items: center;
  }

  .pagination-controls {
    justify-content: center;
  }

  .pagination-options {
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
  }

  .pagination-controls button {
    padding: 6px 12px;
    font-size: 0.9em;
  }

  .selected-page-button {
    font-weight: bold;
  }
}
