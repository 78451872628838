/* src/components/FormBuilder/Fields/MultiRowControl.css */

.multi-row-group label {
  display: block;
  font-weight: 600;
  margin-bottom: 10px;
}

.multi-row {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  position: relative;
}

.multi-row .multi-row-fields {
  display: flex;
  flex-wrap: wrap;
  gap: 15px; /* This adds space between fields */
}

.multi-row-field {
  box-sizing: border-box;
}

.multi-row-field.half-width {
  flex: 0 0 calc(50% - 7.5px); /* Adjust width to account for gap */
}

.multi-row-field.full-width {
  flex: 0 0 100%;
}

.multi-row-actions {
  position: absolute;
  top: 10px;
  right: 10px;
}

.remove-row-button {
  background-color: transparent;
  border: none;
  color: #ff4d4f;
  font-size: 20px;
  cursor: pointer;
}

.add-row-button-container {
  text-align: center;
}

.add-row-button {
  margin-top: 10px;
}
