/* src/components/Policies/PoliciesContainer.css */

.policies-container {
  display: flex;
  min-height: 100vh;
  background: linear-gradient(135deg, #fdfbfb, #ebedee);
  opacity: 0;
  transition: opacity 0.7s ease;
  padding: 20px;
}

.policies-container.fade-in {
  opacity: 1;
}

.policies-left-panel {
  width: 280px;
  background: #fff;
  border-right: 1px solid #e0e0e0;
  padding: 30px;
  box-shadow: 2px 0 6px rgba(0, 0, 0, 0.1);
  margin-right: 20px;
  border-radius: 8px;
}

/* New CSS for the sidebar logo */
.sidebar-logo {
  display: block;
  max-width: 200px;
  height: auto;
  margin: 0 auto 20px; /* Centered with bottom spacing */
}

.policies-left-panel h2 {
  font-size: 1.8em;
  color: #333;
  margin-bottom: 1em;
}

.policies-left-panel button {
  display: block;
  width: 100%;
  padding: 12px 15px;
  margin-bottom: 15px;
  border: none;
  background: #f7f7f7;
  color: #555;
  text-align: left;
  font-size: 1em;
  border-radius: 4px;
  transition: background 0.3s, transform 0.3s;
  cursor: pointer;
}

.policies-left-panel button:hover {
  background: #e9e9e9;
  transform: translateX(5px);
}

.active-tab {
  background: #bb8300;
  color: #fff;
  font-weight: bold;
  transform: none;
}

.home-button {
  background: #f2f2f2;
  color: #444;
  margin-top: 30px;
  padding: 12px;
  border-radius: 4px;
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.home-button:hover {
  background: #e0e0e0;
}

.policies-right-panel {
  flex: 1;
  padding: 30px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .policies-container {
    flex-direction: column;
  }
  
  .policies-left-panel {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    border-right: none;
    border-bottom: 1px solid #e0e0e0;
  }
}
