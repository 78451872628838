/* --------------------------------------------
   1) CSS Variables
--------------------------------------------- */
:root {
  --main-color: #bb8300;       /* Gold-ish primary color */
  --secondary-color: #31423c;  /* Deep green-ish secondary color */
  --light-bg: #f9f9f9;
  --text-color: #fff;

  --button-bg-color: var(--main-color);
  --button-hover-bg-color: var(--secondary-color);

  --input-border-color: #ddd;
  --step-dotted-line-color: #ccc;
  --step-active-line-color: var(--main-color);
}

/* --------------------------------------------
   2) Overall 2-Panel Layout
--------------------------------------------- */
.sign-up-split-container {
  display: flex;
  min-height: 100vh;
  width: 100%;
  background: var(--main-color); /* fallback */
  background: linear-gradient(
    135deg,
    var(--main-color) 20%,
    var(--secondary-color) 100%
  );
}

/* LEFT PANEL */
.sign-up-left-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px;
  background: #fff; /* White background */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

  opacity: 0;
  animation: fadePanelLeft 0.4s forwards;
}

/* Logo at top-left (optional) */
.sign-up-left-logo {
  position: absolute;
  top: 20px;
  left: 20px;
}
.sign-up-left-logo img {
  width: 12vw;
  max-width: 180px;
  height: auto;
}

/* Form content wrapper */
.sign-up-left-content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: 100%;
  text-align: center;
}

/* Subtle fade-in from the left */
@keyframes fadePanelLeft {
  to {
    opacity: 1;
    transform: translateX(0);
  }
  from {
    transform: translateX(-30px);
  }
}

/* RIGHT PANEL */
.sign-up-right-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  /* Transparent overlay to lighten the gradient behind it */
  background-color: rgba(255, 255, 255, 0.05);

  opacity: 0;
  animation: fadePanelRight 0.4s forwards;
  animation-delay: 0.1s;
}

/* Subtle fade-in from the right */
@keyframes fadePanelRight {
  to {
    opacity: 1;
    transform: translateX(0);
  }
  from {
    transform: translateX(30px);
  }
}

/* --------------------------------------------
   3) Footers (Desktop & Mobile)
--------------------------------------------- */
.sign-up-footer.desktop-footer {
  text-align: center;
  padding: 10px 0;
}

.sign-up-policy-links {
  text-align: center;
}
.sign-up-policy-link {
  color: var(--main-color);
  font-weight: 600;
  text-decoration: none;
  margin: 0 10px;
}
.sign-up-policy-separator {
  color: var(--main-color);
}

/* Mobile footer hidden by default */
.sign-up-footer-mobile {
  display: none;
  text-align: center;
  padding: 10px 0;
}
.sign-up-policy-links-mobile {
  text-align: center;
}
.sign-up-policy-link-mobile {
  color: #fff;
  font-weight: 600;
  text-decoration: none;
  margin: 0 10px;
}
.sign-up-policy-separator-mobile {
  color: #fff;
}

/* --------------------------------------------
   4) Vertical Step Wizard (Right Panel)
--------------------------------------------- */
.sign-up-steps-container {
  width: 100%;
  max-width: 480px;
  background: rgba(255, 255, 255, 0.13);
  backdrop-filter: blur(5px);
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.sign-up-progress-bar {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* Step Row Layout: description + circle/marker */
.progress-step-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.progress-step-description {
  flex: 1;
  color: #fff;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 8px;
  display: flex;
  align-items: center;
}

/* Icon Styling */
.progress-step-icon {
  margin-right: 0.75rem;
  font-size: 1.2rem;
  color: #fff;
}

/* Label Styling */
.progress-step-label {
  /* Additional styling if needed */
}

/* The step marker (circle + vertical line) */
.progress-step-marker {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Circle style */
.progress-step-circle {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #fff;
  color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1.1rem;
  position: relative;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}
.progress-step-checkmark {
  font-size: 1.2rem;
  line-height: 1;
}

/* Vertical dotted line connecting steps */
.progress-step-line {
  width: 2px;
  height: 50px;
  border-right: 2px dotted var(--step-dotted-line-color);
  margin-top: 0.3rem;
  transition: border-color 0.3s ease;
}

/* Active & Completed step states */
.progress-step-circle.active {
  background-color: var(--secondary-color);
  color: #fff;
  border-color: #fff;
}

.progress-step-line.active {
  border-right: 2px dotted var(--step-active-line-color);
}

/* --------------------------------------------
   5) Container for Step Content & Login Link
--------------------------------------------- */
.sign-up-step {
  text-align: left; /* Or center if you prefer */
}

.sign-up-login-link {
  margin-top: 20px;
  text-align: center;
}

.link-button {
  background-color: #fff;
  color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
  border-radius: 5px;
  padding: 10px 15px;
  width: 100%;
  max-width: 400px;
  cursor: pointer;
  font-size: 0.95rem;
  font-weight: 600;
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.link-button:hover {
  background-color: var(--secondary-color);
  color: #fff;
}

/* --------------------------------------------
   6) Step Transition Animations (React)
--------------------------------------------- */
.fade-enter {
  opacity: 0;
  transform: translateY(10px);
}
.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}
.fade-exit {
  opacity: 1;
  transform: translateY(0);
}
.fade-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

/* --------------------------------------------
   7) Responsive Layout Adjustments
--------------------------------------------- */
@media (max-width: 992px) {
  .sign-up-split-container {
    flex-direction: column;
  }

  .sign-up-left-panel,
  .sign-up-right-panel {
    width: 100%;
    flex: none;
    animation: none; /* remove side fade if you prefer */
    transform: none;
    opacity: 1;
  }

  /* Hide desktop footer, show mobile footer */
  .desktop-footer {
    display: none;
  }
  .sign-up-footer-mobile {
    display: block;
    margin-top: auto;
  }

  /* Logo adjustments for small screens */
  .sign-up-left-logo {
    position: static;
    text-align: center;
    margin-bottom: 20px;
  }
  .sign-up-left-logo img {
    width: 30vw;
    max-width: 150px;
  }

  /* --------------------------------------------
     8) Responsive Step Wizard (Horizontal for Tablets & Mobiles)
  --------------------------------------------- */
  .sign-up-progress-bar {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .progress-step-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }

  .progress-step-description {
    display: none; /* Hide labels on smaller screens */
  }

  .progress-step-marker {
    flex-direction: column;
    align-items: center;
  }

  /* Adjust the connecting lines to be horizontal */
  .progress-step-line {
    width: 50px;
    height: 2px;
    border-right: none;
    border-bottom: 2px dotted var(--step-dotted-line-color);
    margin: 0;
  }

  .progress-step-wrapper:last-child .progress-step-line {
    display: none; /* Hide line after the last step */
  }

  /* Adjust circle size for smaller screens */
  .progress-step-circle {
    width: 32px;
    height: 32px;
    font-size: 1rem;
  }

  .progress-step-checkmark {
    font-size: 1rem;
  }
}

@media (max-width: 600px) {
  .progress-step-circle {
    width: 28px;
    height: 28px;
    font-size: 0.9rem;
  }

  .progress-step-line {
    width: 30px;
    height: 2px;
  }
}

/* Reduced motion preference */
@media (prefers-reduced-motion: reduce) {
  .sign-up-left-panel,
  .sign-up-right-panel,
  .fade-enter,
  .fade-enter-active,
  .fade-exit,
  .fade-exit-active {
    animation: none !important;
    transition: none !important;
  }
}
