/* src/components/FormManager/GeneralTab.css */

.general-tab-container {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    max-width: 1000px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
  }
  
  .switch-control {
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  /* Label for the toggle switch */
.switch-label {
    margin-right: 10px;
    font-size: 0.9rem;
  }


  .form-builder-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }


  /* Responsive Design */
  @media (max-width: 768px) {
    
    .general-tab-container {
        padding: 15px;
    }

    .form-builder-header {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 15px;
      }
  
    .switch-label {
      font-size: 0.95rem;
    }
  }
  