/* src/components/Common/GlobalSpinner.css */

.global-spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Background color is controlled via inline styles */
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    z-index: 9999;
  }
  
  .global-spinner-overlay.fade-in-active {
    opacity: 1;
  }
  
  .global-spinner-overlay.fade-out-active {
    opacity: 0;
  }
  
  .global-spinner-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    /* Transition duration is controlled via inline styles */
  }
  
  .global-spinner-content.fade-in-active {
    opacity: 1;
  }
  
  .global-spinner-content.fade-out-active {
    opacity: 0;
  }
  
  /* Style for the logo */
  .global-spinner-logo {
    max-width: 80%;
    max-height: 200px;
    margin-bottom: 20px;
    opacity: 1;
  }
  
  .logo-fade-in {
    animation: fadeInLogo forwards;
  }
  
  .logo-fade-out {
    animation: fadeOutLogo forwards;
  }
  
  @keyframes fadeInLogo {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeOutLogo {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  /* Ensure the spinner does not have absolute positioning */
  .custom-spinner {
    /* Remove position if previously set */
  }
  