/* Container that holds the icon and input side-by-side */
.search-bar-container {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid var(--main-color);
  border-radius: 6px;
  background-color: #fff;
  padding: 0 8px;
  box-sizing: border-box; /* Ensure padding is included in total width */

  /* Soft shadow for a more elegant appearance */
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.search-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--main-color);
  margin-right: 6px; /* Slightly smaller gap for a cleaner look */
}

.search-icon {
  font-size: 1.1em;
}

/* The actual text input */
.search-bar {
  border: none;
  outline: none;
  flex: 1; /* Fill the remaining horizontal space */
  font-size: 1rem;
  background-color: transparent;
  /* Vertical padding, horizontal handled by container padding */
  padding: 8px 0;
  color: #333;
}

/* Subtle placeholder color */
.search-bar::placeholder {
  color: #aaa;
  opacity: 1; /* Ensure consistent placeholder visibility */
}

/* Change border color & background on focus */
.search-bar-container:focus-within {
  border-color: var(--main-color);
  background-color: #fafafa;
}

/* Spinner container if isLoading is true */
.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
}

/* 
  Add responsive breakpoints 
  (feel free to adjust values & rules to fit your design) 
*/

/* Medium screens (e.g., tablets) */
@media (max-width: 768px) {
  .search-bar-container {
    padding: 0 6px; 
  }
  .search-bar {
    font-size: 0.95rem;
    padding: 6px 0;
  }
}

/* Small screens (e.g., phones) */
@media (max-width: 480px) {
  .search-bar-container {
    padding: 0 4px;
  }
  .search-icon {
    font-size: 1em; 
  }
  .search-bar {
    font-size: 0.9rem;
    padding: 4px 0;
  }
}
