/* src/components/MemberTable/MembersTable.css */

/* Define CSS variables */
:root {
  --main-color: #bb8300;
  --secondary-color: #31423c;
  --background-color: #f5f5f5;
  --font-family: 'Arial', sans-serif;
  --font-size: 1em;
  --padding: 20px;
  --table-header-bg: var(--main-color);
  --table-header-text-color: #ffffff;
  --table-row-bg-alt: #f3f3f3;
  --table-border-color: #dddddd;
  --button-bg-color: var(--main-color);
  --button-text-color: #ffffff;
  --button-hover-bg-color: var(--main-color);
  --input-border-color: #ced4da;
  --fade-in-duration: 0.5s;
  --fade-out-duration: 0.3s;
  --typing-animation-duration: 0.3s;
}

/* Container for the entire table component */
.member-table-container {
  padding: 10px 0; /* similar to TeamsTable spacing */
}

/* This single flex container holds the SearchBar and Add button on one row */
.members-header-actions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

/* Record count styling */
.record-count {
  font-size: 16px;
  color: var(--main-color);
  text-align: center; /* optional, or left-align if you prefer */
  margin-bottom: 10px;
}

/* Search Bar styling */
.search-bar {
  flex: 1;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid var(--input-border-color);
  font-size: 1.1em;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.search-bar:focus {
  border-color: var(--main-color);
  background-color: #f9f9f9;
}

.search-bar.typing {
  background-color: #e9ecef;
}

/* Action Button styling */
.action-button {
  background-color: var(--main-color) !important;
  color: var(--button-text-color) !important;
  border: none !important;
  padding: 10px 20px !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  font-size: 1em !important;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.action-button:hover {
  background-color: var(--main-color) !important;
  transform: translateY(-2px);
}

/* Member Table styling */
.member-table {
  width: 100%;
  border-collapse: collapse;
  margin: 15px 0;
  font-size: var(--font-size);
  font-family: var(--font-family);
}

.member-table thead tr {
  background-color: var(--table-header-bg);
  color: var(--table-header-text-color);
  text-align: left;
}

.member-table th,
.member-table td {
  padding: 12px 16px;
}

.member-table tbody tr:nth-of-type(even) {
  background-color: var(--table-row-bg-alt);
}

.member-table tbody tr:last-of-type {
  border-bottom: 2px solid var(--table-header-bg);
}

.member-table tbody tr:hover {
  background-color: #e9ecef;
}

/* Sortable column header */
.sortable {
  cursor: pointer;
  user-select: none;
}

.sort-indicator {
  margin-left: 5px;
  font-size: 0.8em;
}

/* Action Buttons within each table row */
.member-table td .action-button {
  margin-right: 10px;
  background-color: var(--main-color) !important;
  color: var(--button-text-color) !important;
  border: none !important;
  padding: 8px 14px !important;
  border-radius: 6px !important;
  cursor: pointer !important;
  font-size: 0.9em !important;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.member-table td .action-button:hover {
  background-color: var(--main-color) !important;
  transform: translateY(-1px);
}

.member-table td .action-button:last-child {
  margin-right: 0;
}

/* Pagination styles */
.pagination-top,
.pagination-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}

.pagination-top {
  margin-bottom: 20px;
}

.pagination-top .pagination-options {
  margin-right: auto;
  display: flex;
  align-items: center;
}

.pagination-top .pagination-controls,
.pagination-controls {
  display: flex;
  align-items: center;
}

.pagination-options label {
  margin-right: 10px;
  font-weight: bold;
}

.pagination-options select {
  padding: 8px 10px;
  border-radius: 6px;
  border: 1px solid var(--input-border-color);
  font-size: 1em;
  transition: border-color 0.3s ease;
}

.pagination-options select:focus {
  border-color: var(--main-color);
  outline: none;
}

.pagination-controls button {
  margin: 5px;
  background-color: var(--main-color);
  color: var(--button-text-color);
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.pagination-controls button:hover {
  background-color: var(--main-color);
  transform: translateY(-1px);
}

.pagination-controls button[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}

.selected-page-button {
  background-color: var(--main-color) !important;
  color: #ffffff !important;
  border-color: var(--main-color) !important;
}

.page-button {
  background-color: #333 !important;
  color: #fff !important;
  border-color: var(--main-color) !important;
}

.page-button:hover {
  background-color: var(--main-color) !important;
  color: #fff !important;
}
