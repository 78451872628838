/* src/components/Users.css */

/* Define CSS variables if not already defined */
:root {
  --main-color: #bb8300; /* Primary color */
  --secondary-color: #31423c; /* Darker shade for hover effects */
}

/* General Styles */
.users-table-container {
  padding: 20px;
  background-color: #fff;
}

.users-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.users-table-header h2 {
  color: var(--main-color);
}

.users-add-button {
  background-color: var(--main-color);
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
  display: flex;
  align-items: center;
}

.users-add-button:hover {
  background-color: var(--secondary-color);
}

.users-add-button svg {
  margin-right: 5px;
}

.users-table {
  width: 100%;
  border-collapse: collapse;
}

.users-table th,
.users-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.users-table th {
  background-color: var(--main-color);
  color: #fff;
}

.users-action-button {
  background-color: var(--main-color);
  color: #fff;
  border: none;
  padding: 8px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  margin-right: 5px;
  margin-bottom: 5px;
  display: inline-flex;
  align-items: center;
}

.users-action-button:hover {
  background-color: var(--secondary-color);
}

.users-action-button svg {
  margin-right: 5px;
}

/* Modal Form Styles */
.users-modal-form {
  display: flex;
  flex-direction: column;
}

.users-input-group {
  margin-bottom: 15px;
}

.users-input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #000;
}

.users-input-group input,
.users-input-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.users-modal-submit-button {
  padding: 10px;
  background-color: var(--main-color);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.users-modal-submit-button:hover {
  background-color: var(--secondary-color);
}

/* Delete Confirmation Modal Styles */
.delete-confirmation,
.reset-confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: var(--main-color);
}

.delete-confirmation-actions,
.reset-confirmation-actions {
  margin-top: 20px;
  display: flex;
  gap: 20px;
}

.confirm-button,
.cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.confirm-button {
  background-color: #dc3545; /* Bootstrap Danger Color */
  color: #fff;
}

.confirm-button:hover {
  background-color: #c82333;
}

.cancel-button {
  background-color: #6c757d; /* Bootstrap Secondary Color */
  color: #fff;
}

.cancel-button:hover {
  background-color: #5a6268;
}

/* Hide columns on mobile */
@media (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }

  .users-table-header h2 {
    font-size: 1.1rem;
  }

  .users-add-button {
    padding: 6px 10px;
    font-size: 0.9em;
  }

  .users-table th,
  .users-table td {
    padding: 6px;
    font-size: 0.8em;
  }

  /* Adjusted Action Buttons on Mobile */
  .users-action-button {
    padding: 8px;
    font-size: 0.8em;
    margin-right: 5px;
    margin-bottom: 5px;
    width: 36px;
    height: 36px;
    justify-content: center;
  }

  .users-action-button svg {
    margin-right: 0;
  }

  .users-action-button:last-child {
    margin-right: 0;
  }
}

/* src/components/UsersHeader.css */

.users-table-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px; /* Adjust as needed */
  }
  
  .users-table-header h2 {
    flex: 1;
    text-align: center;
    margin: 0;
    color: var(--main-color);
    font-size: 2rem;
    font-weight: var(--font-weight-bold);
  }
  
  .users-header-actions {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }