/* src/components/FormBuilder/FormBuilder.css */

/* Container for the FormBuilder component */
.form-builder-container {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

/* Styles for the Builder tab */
.builder-tab {
  padding: 20px;
}

/* Controls within the header, such as the form name input */
.form-builder-controls {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  margin-bottom: 25px;
}

/* Label for the form name input */
.form-name-label {
  font-weight: bold;
  font-size: 1rem;
  color: #343a40;
}

/* Input field for the form name */
.form-name-input {
  padding: 10px 12px;
  border: 1px solid #ced4da;
  border-radius: 6px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.form-name-input:focus {
  border-color: #80bdff;
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

/* Warning message when form name is required */
.form-name-warning {
  padding: 10px 12px;
  background-color: #fff3cd;
  color: #856404;
  border: 1px solid #ffeeba;
  border-radius: 6px;
  font-size: 0.95rem;
}

/* Container for the toggle switch */
.switch-control {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #343a40;
}

/* Container for the fields in the form */
.fields-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

/* Summary box for each field */
.field-summary {
  position: relative; /* Added for positioning order buttons */
  border: 1px solid #dee2e6;
  padding: 15px;
  border-radius: 6px;
  background-color: #f8f9fa;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(50% - 20px); /* Half width by default */
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.field-summary:hover {
  background-color: #e2e6ea;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

/* Full-width field summary */
.field-summary.full-width {
  width: 100%;
}

/* Half-width field summary */
.field-summary.half-width {
  width: calc(50% - 20px);
}

/* Content within the field summary */
.field-summary-content {
  display: flex;
  flex-direction: column;
}

/* Label of the field */
.field-label {
  font-weight: bold;
  color: #343a40;
}

/* Asterisk indicating a required field */
.required-asterisk {
  color: #dc3545;
}

/* Type information of the field */
.field-type {
  font-size: 0.9rem;
  color: #6c757d;
}

/* Button to add a new field with a plus icon */
.add-field-button.with-plus {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 50px;
  border: 2px dashed #ced4da;
  border-radius: 6px;
  background-color: #e9ecef;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1.2rem;
  color: #495057;
}

.add-field-button.with-plus:hover {
  background-color: #dee2e6;
}

.add-field-button.with-plus.disabled,
.add-field-button.with-plus:disabled {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  color: #6c757d;
  cursor: not-allowed;
}

/* Plus icon within the add field button */
.plus-icon {
  font-size: 1.5rem;
  margin-right: 8px;
}

/* Full-width add field button */
.add-field-button.full-width {
  width: 100%;
}

/* Button to remove a field */
.remove-field-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: transparent;
  color: #dc3545;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
  line-height: 1;
}

.remove-field-button:hover {
  color: #c82333;
}

/* Wrapper for the field selector component */
.field-selector-wrapper.full-width {
  width: 100%;
}

.field-selector-wrapper.half-width {
  width: calc(50% - 20px); /* Adjust based on the gap */
}

/* Container for the buttons */
.button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.button-container .action-button {
  flex: 1;
  min-width: 150px;
}

/* Container for the save form button */
.save-button-container {
  margin-top: 30px;
}

/* Save form button styling */
.save-form-button {
  width: 100%;
  padding: 12px 20px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.save-form-button:hover {
  background-color: #0069d9;
}

.save-form-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

/* Wrapper for the field selector to handle positioning */
.field-selector-wrapper {
  position: relative;
}

/* Styles for the toggle switch slider */
.toggle-switch .slider {
  background-color: #ccc;
  transition: background-color 0.4s;
}

.toggle-switch input:checked + .slider {
  /* The background color is set inline via the style prop in ToggleSwitch.jsx */
}

/* Warning message for form name setup */
.form-name-warning {
  padding: 10px 12px;
  background-color: #fff3cd;
  color: #856404;
  border: 1px solid #ffeeba;
  border-radius: 6px;
  font-size: 0.95rem;
}

/* **New: Styles for Order Buttons using ActionButton** */
.field-order-buttons {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  flex-direction: row; /* Changed from column to row for horizontal alignment */
  gap: 5px; /* Space between the buttons */
}

/* Specific styling for ActionButtons used as order buttons */
.order-action-button {
  padding: 5px;
  font-size: 1rem;
}

/* Override default spacing for order buttons */
.order-action-button.action-button {
  margin: 0; /* Remove external spacing */
}

/* **New: Styles for Custom Context Menu** */
.custom-context-menu {
  position: fixed;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  list-style: none;
  padding: 5px 0;
  margin: 0;
  z-index: 1000; /* Ensure it appears above other elements */
  width: 150px;
}

.custom-context-menu li {
  padding: 8px 12px;
  cursor: pointer;
  font-size: 0.9rem;
  color: #343a40;
}

.custom-context-menu li:hover {
  background-color: #f1f1f1;
}

/* Optional: Add a small triangle indicator */
.custom-context-menu::before {
  content: '';
  position: absolute;
  top: -8px;
  left: 10px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ced4da transparent;
}

@media (max-width: 768px) {
  /* Adjust context menu width for smaller screens */
  .custom-context-menu {
    width: 120px;
  }

  .custom-context-menu li {
    font-size: 0.8rem;
    padding: 6px 10px;
  }
}

/* **Ensure field-order-buttons are aligned horizontally** */
.field-order-buttons {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  flex-direction: row; /* Changed from column to row for horizontal alignment */
  gap: 5px; /* Space between the buttons */
}

/* Responsive Adjustments for Order Buttons */
@media (max-width: 768px) {
  .field-order-buttons {
    bottom: 5px;
    right: 5px;
    flex-direction: row; /* Keep horizontal alignment on mobile */
    gap: 3px; /* Reduced space on smaller screens */
  }

  .order-action-button {
    font-size: 0.9rem;
    padding: 4px;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .form-builder-container {
    padding: 15px;
  }

  .form-builder-controls {
    width: 100%;
  }

  .fields-container {
    gap: 15px;
  }

  .field-summary {
    width: 100%;
  }

  .add-field-button.with-plus,
  .add-field-button.full-width {
    width: 100%;
    justify-content: center;
  }

  .field-selector-wrapper.full-width,
  .field-selector-wrapper.half-width {
    width: 100%;
  }

  .save-button-container {
    margin-top: 20px;
  }

  /* **Responsive Adjustments for Order Buttons** */
  .field-order-buttons {
    bottom: 5px;
    right: 5px;
    flex-direction: row; /* Keep horizontal alignment on mobile */
    gap: 3px; /* Reduced space on smaller screens */
  }

  .order-action-button {
    font-size: 0.9rem;
    padding: 4px;
  }

  .size-options {
    flex-direction: column;
  }

  .option-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .remove-option-button {
    align-self: flex-end;
  }
}

/* Container for the top buttons */
.top-button-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 20px;
}

/* Adjust the icon-only buttons */
.top-button-container .action-button {
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-button-container .action-button .button-icon {
  font-size: 1.2rem;
}

/* Styles for the dropdown menu */
.menu-container {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  position: absolute;
  top: 40px; /* Adjust based on button size */
  right: 0;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  min-width: 200px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
}
.dropdown-menu button {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border: none;
  width: 100%;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: #343a40;
  text-align: left;
  font-size: 14px;
}

.dropdown-menu button:hover {
  background-color: #f0f0f0;
}

.dropdown-menu .menu-icon {
  margin-right: 8px;
}

.menu-spinner {
  margin-left: auto;
}

.dropdown-menu button {
  background-color: transparent;
  border: none;
  color: #343a40;
  padding: 12px 16px;
  text-align: left;
  width: 100%;
  cursor: pointer;
  font-size: 14px;
}

.dropdown-menu button:hover {
  background-color: #f1f1f1;
}

@media (max-width: 768px) {
  .top-button-container {
    justify-content: center;
  }

  .top-button-container .action-button {
    width: 36px;
    height: 36px;
  }

  .top-button-container .action-button .button-icon {
    font-size: 1rem;
  }
}