.team-types-modal-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .team-types-card {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    background-color: #fafafa;
  }
  
  /* Existing Team Type row: icon + name fill width */
  .team-types-main {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1; /* This ensures the row can stretch */
  }
  
  .type-name-input {
    flex: 1; /* This makes the input take remaining width */
    padding: 6px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 0.9rem;
  }
  
  .team-types-card-actions {
    display: flex;
    gap: 0.5rem;
  }
  
  .add-team-type-section {
    margin-top: 1rem;
    padding: 10px;
    border: 1px dashed #ccc;
    border-radius: 6px;
    background-color: #fff;
  }
  
  .add-team-type-section h4 {
    margin: 0 0 10px;
    font-weight: 600;
    color: #333;
  }
  
  .add-team-type-row {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  /* The input for new team type also expands */
  .add-team-type-row .type-name-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .team-types-card:hover {
    background-color: #f1f1f1;
  }
  