/* src/components/CustomModal.css */

/* Base Modal Styles */
.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.custom-modal-overlay.fade-in-active {
  opacity: 1;
}

.custom-modal-overlay.fade-out-active {
  opacity: 0;
}

.custom-modal {
  background-color: #fff;
  border-radius: 12px;
  width: 95%; /* Increased width */
  max-width: 1200px; /* Increased max-width */
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transform: scale(1);
  transition: transform 300ms ease-in-out;
}

.custom-modal-header {
  background-color: var(--main-color);
  color: #fff;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-modal-header h2 {
  margin: 0;
  font-size: 1.5em;
}

.custom-modal-close {
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5em;
  cursor: pointer;
  transition: color 0.3s ease;
}

.custom-modal-close:hover {
  color: var(--secondary-color);
}

.custom-modal-body {
  padding: 20px;
  color: var(--main-color);
  line-height: 1.6;
  max-height: 90vh; /* Increased max-height */
  overflow-y: auto;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .custom-modal {
    width: 98%; /* Further increased width for small screens */
    max-width: none; /* Removed max-width for better flexibility */
  }

  .custom-modal-body {
    padding: 15px;
    max-height: 85vh; /* Adjusted max-height for small screens */
  }
}
