.settings-container {
  background-color: #fff;
  padding: 30px;
  border-radius: var(--border-radius);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  max-width: var(--max-width);
  margin: 20px auto;
}

/* Header */
.settings-container h2 {
  text-align: center;
  margin-bottom: 30px;
  color: var(--main-color);
  font-size: 2rem;
  font-weight: var(--font-weight-bold);
}

.form-row {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.half-width {
  flex: 1;
  min-width: calc(50% - 10px);
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }
  .half-width {
    min-width: 100%;
  }
}
