/* src/components/Settings/LanguageSelector.css */

.language-selector-row {
    flex: 0.5;
    min-width: 200px;
    display: flex;
    flex-direction: column;
  }
  
  .language-label {
    font-size: 1rem;
    font-weight: var(--font-weight-medium);
    margin-right: 10px;
    color: var(--main-color);
    margin-bottom: 10px;
  }
  
  .language-select {
    padding: 8px 12px;
    font-size: 1rem;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    background-color: var(--background-color);
    transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
    outline: none;
    color: var(--main-color);
  }
  
  .language-select:focus {
    border-color: var(--main-color);
    background-color: #fff;
  }
  
  .language-select:hover {
    border-color: var(--secondary-color);
  }
  
  .language-select option {
    font-size: 1rem;
  }
  