/* src/components/FormBuilder/Fields/RangeSliderField.css */

.range-slider-group label {
    display: block;
    font-weight: 600;
    margin-bottom: 5px;
  }
  
  .slider-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .range-slider {
    flex-grow: 1;
  }
  
  .slider-value {
    width: 40px;
    text-align: center;
    font-weight: bold;
  }
  