/* Overall container around the image picker */
.image-picker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 200px; /* maximum width for larger screens */
  margin: 0 auto;
}

/* The main wrapper for the label/input */
.image-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
}

/* Hide the native file input, replaced by label click */
.image-picker-input {
  display: none;
}

/* The clickable label that wraps the hidden input */
.image-picker-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

/* A wrapper to position the 3-dot menu, the icon, and the spinner overlay. */
.image-picker-wrapper {
  position: relative;
  width: 100%;
}

/* The box that shows the current image preview (or placeholder).
   This version uses a responsive width and maintains a square aspect ratio.
   (Requires modern browsers that support the aspect-ratio property.) */
.image-picker-icon {
  width: 100%;
  max-width: 200px;         /* Maximum size on larger screens */
  aspect-ratio: 1;          /* Maintains a square shape */
  background-color: var(--main-color, #ddd);
  border: 2px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

/* If imageShape = "circular", apply a round border. */
.image-picker-icon.circular {
  border-radius: 50%;
}

/* If imageShape = "square", apply smaller or zero radius. */
.image-picker-icon.square {
  border-radius: 8px;
}

/* Make the <img> fill the container but display the entire image. */
.image-picker-icon img {
  position: absolute; /* Positioned absolutely inside the container */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

/* Spinner container */
.image-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}

/* Textual label beneath the image */
.image-picker-text {
  font-size: 14px;
  color: var(--main-color, #333);
  cursor: pointer;
}

/* Required field asterisk */
.required-asterisk {
  color: red;
  margin-left: 2px;
}

/* Error message styling */
.image-picker-error {
  color: red;
  font-size: 0.875rem;
  margin-top: 5px;
}

/* The 3-dot menu button */
.three-dot-menu {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  background: var(--main-color, #333);
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  line-height: 1;
  transition: background 0.2s ease;
}

.three-dot-menu:hover {
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
}

/* Compression progress text */
.image-compression-progress {
  margin-top: 8px;
  color: #666;
  font-size: 0.9rem;
}

/* Spinner styles */
.custom-spinner {
  box-sizing: border-box;
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 4px solid #ccc;
  border-top-color: #2e93f9;
  border-radius: 50%;
  animation: spinner-rotate 0.8s linear infinite;
}

@keyframes spinner-rotate {
  to {
    transform: rotate(360deg);
  }
}

/* ----------------------------------------------
   Responsive adjustments for tablets and phones
----------------------------------------------- */

/* For tablets (max-width: 768px) */
@media (max-width: 768px) {
  .image-picker-container {
    max-width: 150px;
  }
  .image-picker-icon {
    max-width: 150px;
  }
  .image-picker-text {
    font-size: 13px;
  }
  .three-dot-menu {
    width: 24px;
    height: 24px;
    font-size: 16px;
  }
}

/* For phones (max-width: 480px) */
@media (max-width: 480px) {
  .image-picker-container {
    max-width: 150px;
  }
  .image-picker-icon {
    max-width: 150px;
  }
  .image-picker-text {
    font-size: 12px;
  }
  .three-dot-menu {
    width: 20px;
    height: 20px;
    font-size: 14px;
  }
}
