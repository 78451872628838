/* src/components/Policies/PrivacyPolicy.css */

.privacy-policy-background {
  min-height: 100vh;
  background: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  box-sizing: border-box;
}

.privacy-policy-container {
  width: 100%;
  background: #ffffff;
  padding: 40px 30px;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  color: #333;
  font-family: 'Segoe UI', sans-serif;
}

.privacy-policy-header {
  text-align: center;
  margin-bottom: 30px;
}

.privacy-policy-logo {
  width: 200px;
  margin-bottom: 15px;
}

.privacy-policy-header h1 {
  font-size: 2.2em;
  margin: 0;
  color: #333;
}

.privacy-policy-header h2 {
  font-size: 1.4em;
  margin-top: 8px;
  color: #666;
}

.policy-dates {
  display: flex;
  justify-content: space-between;
  font-size: 0.9em;
  color: #777;
  margin-bottom: 20px;
}

.policy-dates p {
  margin: 0;
}

.privacy-policy-content h3 {
  color: #bb8300;
  font-size: 1.6em;
  margin: 30px 0 10px;
  border-bottom: 2px solid #bb8300;
  padding-bottom: 5px;
}

.privacy-policy-content p {
  margin-bottom: 1em;
  line-height: 1.6;
}

.privacy-policy-content ul {
  margin-left: 20px;
  margin-bottom: 20px;
}

.privacy-policy-content li {
  margin-bottom: 10px;
}

.privacy-policy-content a {
  color: #bb8300;
  text-decoration: none;
}

.privacy-policy-content a:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .policy-dates {
    flex-direction: column;
    align-items: flex-start;
  }
  .privacy-policy-header h1 {
    font-size: 1.8em;
  }
  .privacy-policy-header h2 {
    font-size: 1.2em;
  }
}
