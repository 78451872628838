/* src/components/Authentication/SignUpSteps/Step6Form.css */

/* Root variables */
:root {
    --main-color: #bb8300;
    --secondary-color: #31423c;
    --input-border-color: #ddd;
    --button-bg-color: var(--main-color);
    --button-hover-bg-color: var(--secondary-color);
    --text-color: #fff;
  }
  
  /* Centered "building organization" message */
  .building-organization {
    text-align: center;
    padding: 40px 20px;
  }
  .building-organization h2 {
    margin-bottom: 20px;
    color: var(--main-color);
  }
  .building-organization p {
    font-size: 1.1em;
    margin-bottom: 30px;
  }
  