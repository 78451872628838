/* src/pages/GroupsPage.css */

/* Groups Page Container */
.groups-page-container {
  background-color: #fff;
  padding: 30px;
  border-radius: var(--border-radius);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  max-width: var(--max-width);
  margin: 20px auto;
}

/* Header Container */
.groups-page-container .header-container {
  position: relative;         /* Allows absolute positioning of the config button */
  display: flex;
  justify-content: center;    /* Center the heading */
  align-items: center;
  margin-bottom: 20px;
}

/* Header Title */
.groups-page-container .header-container h2 {
  margin: 0;                  /* Remove default margins */
  color: var(--main-color);
  font-size: 2rem;
  font-weight: var(--font-weight-bold);
}

/* Configuration / More Options Button */
.groups-page-container .config-button {
  position: absolute;         /* Place it at the top-right */
  top: 0;
  right: 0;
}

/* Loading Container */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;              /* Optional: full viewport height */
}
