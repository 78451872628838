/* src/components/FormBuilder/FormManager.css */

.form-manager-container {
  display: flex;
  align-items: center; /* Centers content vertically */
  justify-content: center; /* Centers content horizontally */
  padding: 20px;
  background-color: #f5f5f5;
  box-sizing: border-box;
  min-height: 20vh; /* Added min-height to increase the overall height */
}

.form-content {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 60vh; /* Added min-height to make content area bigger */
}

.tab-content-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers tab content vertically */
  justify-content: center;
  flex-grow: 1; /* Allows the container to grow and fill available space */
}

.tab-content {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.fade-enter {
  opacity: 0;
  transform: translateY(10px);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
}

/* Info message displayed when form name is not set */
.info-message {
  text-align: center;
  font-size: 1.1rem;
  color: #495057;
  padding: 20px;
  background-color: #e9ecef;
  border-radius: 6px;
  margin-top: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .form-manager-container {
    flex-direction: column;
    padding: 10px;
    min-height: auto; /* Adjusted for smaller screens */
  }

  .form-content {
    width: 100%;
    padding: 15px;
    min-height: auto; /* Adjusted for smaller screens */
  }

  .tab-content {
    padding: 15px;
  }
}
