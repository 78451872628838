/* src/components/Common/NotFound.css */

.not-found-background {
    width: 100%;
    min-height: 100vh;
    background-color: #f8f9fa; /* Light background */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .not-found-container {
    text-align: center;
    background-color: #ffffff; /* White container */
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .not-found-logo {
    width: 150px;
    height: auto;
    margin-bottom: 20px;
  }
  
  .not-found-container h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #dc3545; /* Bootstrap danger color for emphasis */
  }
  
  .not-found-container p {
    font-size: 1.2rem;
    color: #6c757d; /* Muted text color */
    margin-bottom: 20px;
  }
  
  .home-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff; /* Bootstrap primary color */
    color: #ffffff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .home-link:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }
  