/* src/components/FormBuilder/Fields/Section.css */

.form-section {
    width: 100%;
    margin: 10px 0 10px 0;
    padding-bottom: 10px;
    border-bottom: 2px solid var(--main-color);
  }
  
  .form-section-title {
    font-size: 1.5em;
    color: var(--main-color);
    margin: 0;
    padding: 0;
  }
  
  .form-section-description {
    margin: 0;
    font-size: 1em;
    color: #666;
  }