/* src/components/LandingPage/LandingPage.css */

/* 
  Primary Colors / Dimensions
*/
:root {
  --color-background: #ffffff;
  --color-primary: #bb8300;
  --color-text: #333333;
  --color-section-alt: #f9f9f9;
  --color-border: #eaeaea;
  --color-button-hover: #efefef;
  --color-button-cta-hover: #9b6900;

  --hero-bg-gradient: linear-gradient(
    135deg,
    rgba(187, 131, 0, 0.1) 0%,
    rgba(255, 255, 255, 0.7) 70%
  );
}

/* Keyframes for fade-in effect without movement */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Initial state for all sections */
section {
  opacity: 0;
  /* Remove transform to keep elements in place */
  /* If you want a slight movement, uncomment the next line */
  /* transform: translateY(20px); */
  transition: opacity 0.7s ease-out;
}

/* State when section is in view */
section.lp-in-view {
  opacity: 1;
  /* Remove transform to keep elements in place */
  /* If you kept the transform in the initial state, uncomment the next line */
  /* transform: translateY(0); */
}

/* BODY AND BASE STYLES */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  color: var(--color-text);
  background-color: var(--color-background);
}

a {
  text-decoration: none;
}

/* LANDING PAGE CONTAINER */
.lp-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* HEADER */
.lp-logo-desktop {
  display: block;
}
.lp-logo-mobile {
  display: none; /* Shown below 600px */
}

.lp-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid var(--color-border);
  background-color: var(--color-background);
}

/* Desktop vs Mobile Logos */
.lp-logo-desktop img,
.lp-logo-mobile img {
  height: 50px;
}

/* Navigation Links */
.lp-nav-links {
  display: flex;
  align-items: center;
}

/* Hide certain links on mobile */
@media (max-width: 768px) {
  .lp-hide-on-mobile {
    display: none !important;
  }
}

/* Nav items */
.lp-nav-link {
  margin-right: 20px;
  color: var(--color-text);
  font-weight: 500;
  transition: color 0.3s ease;
}
.lp-nav-link:hover {
  color: var(--color-primary);
}

.lp-nav-button {
  padding: 10px 20px;
  margin-left: 10px;
  background-color: var(--color-primary);
  color: #ffffff;
  border-radius: 25px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}
.lp-nav-button:hover {
  background-color: var(--color-button-cta-hover);
}
.lp-create-account-button {
  border: 2px solid var(--color-primary);
}

/* Hide Desktop Logo, Show Mobile Logo on <600px */
@media (max-width: 600px) {
  .lp-logo-desktop {
    display: none;
  }
  .lp-logo-mobile {
    display: block;
  }
}

/* WAVE DIVIDERS */
.lp-wave-divider {
  height: 80px;
  width: 100%;
  background: url("data:image/svg+xml,%3Csvg viewBox='0 0 1440 320' ... %3E") no-repeat center;
  background-size: cover;
}
.lp-wave-top {
  transform: rotate(180deg) translateY(-1px);
}
.lp-wave-bottom {
  margin-bottom: -5px;
}

/* HERO */
.lp-hero {
  background: var(--hero-bg-gradient);
  padding: 40px 20px;
  position: relative;
}
.lp-hero-content.lp-hero-two-column {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}
.lp-hero-left {
  flex: 1;
  max-width: 600px;
  margin-right: 40px;

  /* Fade-in Animation */
  animation: fadeIn 1s ease-out forwards;
  animation-delay: 0.2s;
  opacity: 0;
}

.lp-hero-title {
  font-size: 3em;
  margin: 0;
  color: var(--color-primary);

  /* Fade-in Animation */
  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 0.4s;
  opacity: 0;
}

.lp-tagline {
  font-size: 1.5em;
  font-weight: 400;
  margin: 15px 0 20px;
  color: var(--color-text);

  /* Fade-in Animation */
  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 0.6s;
  opacity: 0;
}

.lp-hero-description {
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #555;

  /* Fade-in Animation */
  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 0.8s;
  opacity: 0;
}

.lp-cta-button {
  padding: 12px 25px;
  background-color: var(--color-primary);
  color: #ffffff;
  border-radius: 25px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: inline-block;

  /* Fade-in Animation */
  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 1s;
  opacity: 0;
}

.lp-cta-button:hover {
  background-color: var(--color-button-cta-hover);
  transform: scale(1.03);
}

.lp-hero-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Fade-in Animation */
  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 1.2s;
  opacity: 0;
}

.lp-hero-image {
  max-width: 100%;
  height: auto;
  max-height: 400px;

  /* Fade-in Animation */
  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 1.4s;
  opacity: 0;
}

/* ABOUT */
.lp-about {
  background-color: var(--color-section-alt);
  position: relative;
  z-index: 1;
  padding: 40px 20px;
  text-align: center;
}
.lp-about-two-column {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.lp-about-left {
  flex: 1;
  margin-right: 40px;
  text-align: left;

  /* Fade-in Animation */
  animation: fadeIn 1s ease-out forwards;
  animation-delay: 0.2s;
  opacity: 0;
}

.lp-about-left h2 {
  font-size: 2em;
  margin-bottom: 15px;
  color: var(--color-primary);
  border-bottom: 3px solid var(--color-primary);
  display: inline-block;
  padding-bottom: 3px;

  /* Fade-in Animation */
  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 0.4s;
  opacity: 0;
}

.lp-about-text {
  margin-bottom: 1.5rem;
  line-height: 1.7;
  text-align: justify;

  /* Fade-in Animation */
  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 0.6s;
  opacity: 0;
}

.lp-about-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Fade-in Animation */
  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 0.8s;
  opacity: 0;
}

.lp-about-image {
  max-width: 100%;
  height: auto;
  max-height: 450px;
  border-radius: 8px;

  /* Fade-in Animation */
  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 1s;
  opacity: 0;
}

@media (max-width: 500px) {
  .lp-hero-image {
    max-height: 250px;
  }
  .lp-about-image {
    max-height: 300px; 
  }
}

@media (max-width: 768px) {
  .lp-about-right {
    margin: 0 auto;
    text-align: center;
  }
}

/* Info Boxes */
.lp-info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 15px;
  padding: 0;

  /* Fade-in Animation */
  animation: fadeIn 1s ease-out forwards;
  animation-delay: 1.2s;
  opacity: 0;
}

.lp-info-box {
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  border-top: 3px solid var(--color-primary);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  /* Fade-in Animation */
  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 1.4s;
  opacity: 0;
}

.lp-info-box h3 {
  margin-top: 0;
  color: var(--color-primary);
  border-bottom: 2px solid var(--color-primary);
  display: inline-block;
  padding-bottom: 3px;

  /* Fade-in Animation */
  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 1.6s;
  opacity: 0;
}

.lp-info-box-hover:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(187,131,0,0.2);
}

.lp-core-values {
  list-style: none;
  margin: 0;
  padding-left: 0;

  /* Fade-in Animation */
  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 1.8s;
  opacity: 0;
}

.lp-core-values li {
  margin: 6px 0;
  position: relative;
  padding-left: 25px;

  /* Fade-in Animation */
  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 2s;
  opacity: 0;
}

.lp-core-values li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  width: 10px;
  height: 10px;
  background-color: var(--color-primary);
  border-radius: 50%;

  /* Fade-in Animation */
  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 2.2s;
  opacity: 0;
}

/* FEATURES */
.lp-features {
  background-color: var(--color-background);
  position: relative;
  z-index: 1;
  padding: 40px 20px;
  text-align: center;
}
.lp-section-heading {
  color: var(--color-primary);
  font-size: 2em;
  margin-bottom: 20px;
  border-bottom: 3px solid var(--color-primary);
  display: inline-block;
  padding-bottom: 3px;

  /* Fade-in Animation */
  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 0.2s;
  opacity: 0;
}

.lp-features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-items: center;
  max-width: 1000px;
  margin: 0 auto;

  /* Fade-in Animation */
  animation: fadeIn 1s ease-out forwards;
  animation-delay: 0.4s;
  opacity: 0;
}

.lp-feature-card {
  background-color: #fff;
  width: 100%;
  padding: 25px 15px;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  /* Fade-in Animation */
  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 0.6s;
  opacity: 0;
}

.lp-feature-icon {
  font-size: 3em;
  color: var(--color-primary);
  margin-bottom: 15px;

  /* Fade-in Animation */
  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 0.8s;
  opacity: 0;
}

.lp-feature-card h3 {
  font-size: 1.2em;
  margin-bottom: 10px;

  /* Fade-in Animation */
  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 1s;
  opacity: 0;
}

.lp-feature-card p {
  font-size: 1em;
  color: #666;

  /* Fade-in Animation */
  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 1.2s;
  opacity: 0;
}

.lp-highlight-outline:hover {
  border: 2px solid var(--color-primary);
}

.lp-feature-card-hover:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(187,131,0,0.2);
}

/* PRICING */
.lp-pricing {
  background-color: var(--color-section-alt);
  position: relative;
  z-index: 1;
  padding: 40px 20px;
  text-align: center;
}
.lp-pricing-intro {
  max-width: 700px;
  margin: 0 auto 30px;
  color: #555;

  /* Fade-in Animation */
  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 0.2s;
  opacity: 0;
}

.lp-pricing-plans {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 1000px;
  margin: 0 auto;

  /* Fade-in Animation */
  animation: fadeIn 1s ease-out forwards;
  animation-delay: 0.4s;
  opacity: 0;
}

/* 1) Make the plan card a vertical flex container */
.lp-plan-card {
  background-color: #fff;
  width: 300px;
  border-radius: 10px;
  padding: 25px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 2px solid transparent;
  position: relative;

  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 0.6s;
  opacity: 0;

  display: flex;            /* NEW */
  flex-direction: column;   /* NEW */
}

.lp-plan-card-featured {
  border: 2px solid var(--color-primary);
}

.lp-plan-card h3 {
  margin-top: 0;
  color: var(--color-primary);
  font-size: 1.3em;
  border-bottom: 2px solid var(--color-primary);
  display: inline-block;
  padding-bottom: 3px;

  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 0.8s;
  opacity: 0;
}

.lp-plan-card h4 {
  font-size: 1.4em;
  margin: 15px 0;
  color: #333;
  font-weight: 700;

  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 1s;
  opacity: 0;
}

.lp-plan-card ul {
  list-style-type: none;
  padding-left: 0;
  margin: 15px 0;
  text-align: left;

  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 1.2s;
  opacity: 0;
}

.lp-plan-card ul li {
  margin: 8px 0;
  font-weight: 500;
  line-height: 1.4;

  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 1.4s;
  opacity: 0;
}

/* 2) Push the button to the bottom of the flex container */
.lp-plan-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--color-primary);
  color: #fff;
  border-radius: 25px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-align: center;

  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 1.6s;
  opacity: 0;

  margin-top: auto; /* NEW */
}

.lp-plan-button:hover {
  background-color: var(--color-button-cta-hover);
  transform: scale(1.03);
}

.lp-plan-card-hover:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(187,131,0,0.2);
  border: 2px solid var(--color-primary);
}

/* "Most Popular" Badge */
.lp-most-popular-badge {
  position: relative;
  overflow: visible;

  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 1.8s;
  opacity: 0;
}

.lp-badge-label {
  position: absolute;
  top: -15px;
  right: -15px;
  background-color: var(--color-primary);
  color: #fff;
  font-size: 0.8em;
  padding: 6px 12px;
  border-radius: 25px;
  font-weight: bold;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);

  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 2s;
  opacity: 0;
}

/* CONTACT */
.lp-contact {
  background-color: var(--color-background);
  position: relative;
  z-index: 1;
  padding: 40px 20px;
  text-align: center;
}
.lp-contact-intro {
  max-width: 700px;
  margin: 0 auto 30px;
  color: #555;

  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 0.2s;
  opacity: 0;
}

.lp-contact-form {
  max-width: 600px;
  margin: 0 auto;
  text-align: left;

  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 0.4s;
  opacity: 0;
}

.lp-form-field {
  margin-bottom: 20px;

  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 0.6s;
  opacity: 0;
}

.lp-form-field label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: var(--color-text);
}

.lp-form-field input,
.lp-form-field textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  font-family: inherit;
  font-size: 1em;
}

.lp-contact-button {
  background-color: var(--color-primary);
  color: #ffffff;
  padding: 12px 25px;
  border: none;
  border-radius: 25px;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;

  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 0.8s;
  opacity: 0;
}

.lp-contact-button:hover {
  background-color: var(--color-button-cta-hover);
  transform: scale(1.03);
}

/* FOOTER */
.lp-footer {
  background-color: var(--color-background);
  color: var(--color-text);
  padding: 15px;
  text-align: center;
  border-top: 1px solid var(--color-border);

  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 1s;
  opacity: 0;
}

.lp-footer p {
  margin: 0;
}

.lp-footer-links {
  margin-top: 10px;

  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 1.2s;
  opacity: 0;
}

.lp-footer-links a {
  color: var(--color-primary);
  margin: 0 10px;
  text-decoration: none;
  font-size: 0.9em;

  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 1.4s;
  opacity: 0;
}

.lp-footer-links a:hover {
  text-decoration: underline;
}

/* MEDIA QUERIES */
@media (max-width: 992px) {
  .lp-hero-content.lp-hero-two-column {
    flex-direction: column;
    text-align: center;
  }
  .lp-hero-left {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .lp-hero-right {
    margin-bottom: 15px;
  }
  .lp-about-two-column {
    flex-direction: column;
    text-align: center;
  }
  .lp-about-left {
    margin-right: 0;
    margin-bottom: 20px;
    text-align: center;
  }
  .lp-about-right {
    margin: 0 auto;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .lp-logo-desktop {
    display: none;
  }
  .lp-logo-mobile {
    display: block;
  }
}

@media (max-width: 500px) {
  .lp-header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .lp-hero-title {
    font-size: 1.8em;
  }
}

/* MARKETING MODAL STYLES */
.lp-marketing-modal {
  text-align: center;
  padding: 20px;

  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 0.2s;
  opacity: 0;
}

.lp-marketing-modal h2 {
  margin-top: 0;
  color: var(--color-primary);
  margin-bottom: 12px;

  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 0.4s;
  opacity: 0;
}

.lp-marketing-modal p {
  font-size: 1.05em;
  line-height: 1.7;
  color: #555;
  margin-bottom: 1rem;

  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 0.6s;
  opacity: 0;
}

.lp-marketing-modal p strong {
  color: var(--color-primary);
}

.lp-marketing-modal a {
  color: var(--color-primary);
  font-weight: 600;
  margin: 0 4px;
  text-decoration: none;

  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 0.8s;
  opacity: 0;
}

.lp-marketing-modal a:hover {
  text-decoration: underline;
}

.lp-modal-btn-wrapper {
  margin-top: 20px;

  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 1s;
  opacity: 0;
}

.lp-modal-btn-wrapper button {
  background-color: var(--color-primary);
  color: #fff;
  padding: 12px 25px;
  border: none;
  border-radius: 25px;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;

  animation: fadeIn 0.8s ease-out forwards;
  animation-delay: 1s;
  opacity: 0;
}

.lp-modal-btn-wrapper button:hover {
  background-color: var(--color-button-cta-hover);
  transform: scale(1.03);
}

/* WAVE DIVIDER ANIMATIONS */
.lp-wave-divider {
  /* If you want to animate the wave, you can add animations here */
  /* Example: Simple fade-in */
  animation: fadeIn 1s ease-out forwards;
  animation-delay: 0s;
  opacity: 0;
}

.lp-wave-top {
  transform: rotate(180deg) translateY(-1px);
}

.lp-wave-bottom {
  margin-bottom: -5px;
}

/* Disable animations for users who prefer reduced motion */
@media (prefers-reduced-motion: reduce) {
  section,
  .lp-hero-content,
  .lp-hero-left,
  .lp-hero-title,
  .lp-tagline,
  .lp-hero-description,
  .lp-cta-button,
  .lp-hero-right,
  .lp-hero-image,
  .lp-about-two-column,
  .lp-about-left,
  .lp-about-left h2,
  .lp-about-text,
  .lp-about-right,
  .lp-about-image,
  .lp-info-grid,
  .lp-info-box,
  .lp-info-box h3,
  .lp-info-box ul,
  .lp-info-box ul li,
  .lp-plan-card,
  .lp-plan-card-featured,
  .lp-plan-card h3,
  .lp-plan-card h4,
  .lp-plan-card ul,
  .lp-plan-card ul li,
  .lp-plan-button,
  .lp-most-popular-badge,
  .lp-badge-label,
  .lp-features-grid,
  .lp-feature-card,
  .lp-feature-icon,
  .lp-feature-card h3,
  .lp-feature-card p,
  .lp-cta-button,
  .lp-hero-image,
  .lp-contact-intro,
  .lp-contact-form,
  .lp-form-field,
  .lp-form-field label,
  .lp-form-field input,
  .lp-form-field textarea,
  .lp-contact-button,
  .lp-footer,
  .lp-footer-links,
  .lp-footer-links a,
  .lp-marketing-modal,
  .lp-marketing-modal h2,
  .lp-marketing-modal p,
  .lp-marketing-modal a,
  .lp-modal-btn-wrapper,
  .lp-modal-btn-wrapper button,
  .lp-wave-divider,
  .lp-most-popular-badge,
  .lp-badge-label,
  .lp-info-box,
  .lp-info-box h3,
  .lp-info-box ul,
  .lp-info-box ul li,
  .lp-plan-card,
  .lp-plan-card-featured,
  .lp-plan-card h3,
  .lp-plan-card h4,
  .lp-plan-card ul,
  .lp-plan-card ul li,
  .lp-plan-button,
  .lp-feature-card,
  .lp-feature-icon,
  .lp-feature-card h3,
  .lp-feature-card p,
  .lp-contact-intro,
  .lp-contact-form,
  .lp-form-field,
  .lp-form-field label,
  .lp-form-field input,
  .lp-form-field textarea,
  .lp-contact-button,
  .lp-footer,
  .lp-footer-links,
  .lp-footer-links a {
    animation: none;
    opacity: 1;
  }
}
