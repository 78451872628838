/* Root variables */
:root {
  --main-color: #bb8300;
  --secondary-color: #31423c;
  --input-border-color: #ddd;
  --button-bg-color: var(--main-color);
  --button-hover-bg-color: var(--secondary-color);
  --text-color: #fff;
}

/* New container to constrain the form width */
.step2-container {
  width: 100%;
  max-width: 500px;  /* Adjust as needed */
  margin: 0 auto;
}

/* Basic form container */
.sign-up-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
  margin-top: 20px;
}

/* Code inputs container */
.sign-up-code-inputs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.sign-up-code-input {
  width: 40px;
  height: 50px;
  margin: 0 5px;
  font-size: 32px;
  text-align: center;
  border: 1px solid var(--input-border-color);
  border-radius: 5px;
  color: var(--main-color);
  font-weight: 600;
}

.sign-up-code-input:focus {
  border-color: var(--main-color);
  outline: none;
}

/* Primary submit button */
.sign-up-submit-button {
  width: 100%;
  padding: 12px 15px;
  border: none;
  border-radius: 5px;
  background-color: var(--button-bg-color);
  color: #fff;
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.sign-up-submit-button:hover {
  background-color: var(--button-hover-bg-color);
}

/* Back button (outline style) */
.sign-up-back-button {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid var(--main-color);
  border-radius: 5px;
  background-color: #fff;
  color: var(--main-color);
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.sign-up-back-button:hover {
  background-color: var(--main-color);
  color: #fff;
}

/* Resend code container & button */
.resend-code-container {
  margin-top: 20px;
  text-align: center;
}

.resend-code-button {
  background-color: transparent;
  border: none;
  color: var(--main-color);
  font-size: 16px;
  cursor: pointer;
  text-decoration: underline;
}

.resend-code-button:disabled {
  color: #aaa;
  cursor: not-allowed;
  text-decoration: none;
}

/* Error / Success messages */
.error-message, .success-message {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.error-message {
  background-color: rgba(255, 0, 0, 0.1);
  color: rgb(96, 8, 8);
}

.success-message {
  background-color: rgba(0, 128, 0, 0.1);
  color: var(--main-color);
}

/* Icon styles */
.error-icon, .success-icon {
  margin-right: 8px;
  font-size: 1.2em;
}
