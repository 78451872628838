/* MAIN 2-PANEL CONTAINER */
.orgsplit-container {
  display: flex;
  width: 100%;
  min-height: 100vh;
  /* We'll fade in the container or do transitions on background */
}

/* For the overall fade background transition */
.fade-bg {
  transition: background-color 0.6s ease;
}

/* LOADING STATE: center spinner + text */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;
}

/* LEFT PANEL */
.org-left-panel {
  flex: 1;
  position: relative;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

/* Catholicore logo top-left */
.org-left-logo {
  position: absolute;
  top: 20px;
  left: 20px;
}
.org-left-logo img {
  width: 140px;
  height: auto;
  object-fit: cover;
}

/* Logout button top-right corner */
.org-logout-button {
  position: absolute;
  top: 20px;
  right: 20px;
}
.org-logout-button button {
  border: none;
  outline: none;
  border-radius: 30px;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: opacity 0.2s, box-shadow 0.2s;
  display: flex;
  align-items: center;
  gap: 8px;
}
.org-logout-button button:hover {
  opacity: 0.9;
  box-shadow: 0 2px 5px rgba(0,0,0,0.15);
}

/* Title for organization selection */
.org-left-title {
  margin: 100px auto 1rem;
  font-size: 1.8rem;
  color: #333;
  font-weight: 700;
  text-align: center;
}

/* Organization list container with fade in for items */
.org-list-container {
  background: #fff;
  border-radius: 12px;
  padding: 30px;
  max-width: 550px;
  width: 90%;
  margin: 0 auto;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* UL of organizations (desktop: standard block list) */
.org-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Fade-in for the entire container and each item */
.fade-cards {
  animation: fadeIn 0.5s ease forwards;
}
.fade-cards-item {
  opacity: 0;
  animation: fadeInItem 0.5s ease forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInItem {
  0% {
    opacity: 0;
    transform: translateY(4px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Organization list item (base) */
.org-list-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  background-color: #fafafa;
  margin: 14px 0;
  padding: 16px 20px;
  border: 2px solid transparent; /* highlight border if selected */
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s, border-color 0.2s;
  color: #444;
  font-weight: 500;
  font-size: 1.1rem;
  opacity: 0; /* fadeInItem sets it to 1 */
}
.org-list-item:hover {
  background-color: #eee;
  box-shadow: 0 2px 4px rgba(0,0,0,0.08);
}

/* Org item logo */
.org-item-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
  object-fit: cover;
}

/* Org item name */
.org-item-name {
  margin-left: 10px;
  flex: 1 1 auto;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

/* RIGHT PANEL */
.org-right-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center; /* center vertically */
  align-items: center;     /* center horizontally */
  padding: 20px;
  color: #fff;
  position: relative;
  transition: background-color 0.6s ease;
}

/* Title near the top */
.org-right-panel h2 {
  margin-top: 10px;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}

/* Container for group cards: flex to center horizontally, wrap if needed */
.org-groups-container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
  align-items: center;
  max-width: 900px;
}

/* 
   Each group card (desktop default).
   We'll override these sizes at smaller breakpoints.
*/
.org-group-card {
  width: 240px;
  height: 280px;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid #fff;
  text-align: center;
  position: relative;
  box-shadow: 0 4px 12px rgba(0,0,0,0.2);
  transition: transform 0.2s ease;
}
.org-group-card:hover {
  transform: translateY(-4px);
}

/* Group logo or placeholder */
.org-group-logo,
.org-group-placeholder {
  width: 90px;
  height: 90px;
  margin: 0 auto 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-size: 2rem;
  object-fit: cover;
}
.org-group-placeholder {
  color: #fff;
  background-color: rgba(0,0,0,0.3);
}

/* Title in the group card */
.org-group-card h4 {
  margin-bottom: 15px;
  font-size: 1.2rem; /* default desktop size */
  font-weight: 600;
}

/* The "Enter" button at bottom */
.org-group-card button {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  border: none;
  background-color: #fff;
  color: #000;
  padding: 10px 20px;
  border-radius: 15px;
  font-size: 0.95rem;
  cursor: pointer;
  font-weight: 600;
}
.org-group-card button:hover {
  background-color: #eaeaea;
}

/* If we're in the 3-second delay, show a small spinner + message */
.loading-groups-delay {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-top: 20px;
}

/* 
   DESKTOP OVERRIDE:
   Above 992px, center the org name and pin the logo on the left.
   We'll do that with absolute positioning for the logo, 
   extra left padding in the list item, etc.
*/
@media (min-width: 992px) {
  .org-list-item {
    position: relative;
    /* Add left padding so the text doesn't overlap the logo */
    padding-left: 70px; 
    justify-content: center; /* center the item text horizontally */
    text-align: center;
  }
  .org-item-logo {
    position: absolute;
    left: 20px;
    margin-right: 0;
  }
  .org-item-name {
    margin-left: 0; /* remove the default left margin */
  }
}

/* <= 992px: stack panels vertically, etc. */
@media (max-width: 992px) {
  .orgsplit-container {
    flex-direction: column;
  }
  .org-left-panel,
  .org-right-panel {
    width: 100%;
  }
  .org-left-title {
    margin-top: 80px;
  }
  .org-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columns for tablets/laptops */
    gap: 12px;
  }
  .org-list-item {
    margin: 0;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 12px;
    height: auto;
    position: static; /* override the desktop absolute logic */
  }
  .org-item-logo {
    position: static;
    left: auto;
    margin-right: 0;
    width: 50px; 
    height: 50px;
  }
  .org-item-name {
    margin-left: 0;
    margin-top: 6px;
    font-size: 0.85rem;
    line-height: 1.2rem;
    max-width: 80%;
  }

  /* smaller group cards for tablets */
  .org-group-card {
    width: 220px; 
    height: 210px;
  }
  .org-group-logo,
  .org-group-placeholder {
    width: 80px;
    height: 80px;
    font-size: 1.8rem;
  }
  /* smaller h4 text on tablet */
  .org-group-card h4 {
    font-size: 1.1rem;
  }
}

/* <= 768px: switch from 3 columns to 2 columns, etc. */
@media (max-width: 768px) {
  .org-list {
    grid-template-columns: repeat(2, 1fr);
  }
  .org-group-card {
    width: 200px;
    height: 210px;
  }
  .org-group-logo,
  .org-group-placeholder {
    width: 70px;
    height: 70px;
    font-size: 1.6rem;
  }
  /* smaller h4 text on smaller tablets */
  .org-group-card h4 {
    font-size: 1rem; 
  }
}

/* <= 480px (mobile): hide names, keep 2 columns, etc. */
@media (max-width: 480px) {
  .org-item-name {
    display: none;
  }
  .org-item-logo {
    width: 60px;
    height: 60px;
  }
  .org-group-card {
    width: 180px;
    height: 210px;
  }
  .org-group-logo,
  .org-group-placeholder {
    width: 60px;
    height: 60px;
    font-size: 1.4rem;
  }
  /* even smaller h4 for mobile */
  .org-group-card h4 {
    font-size: 0.9rem;
  }
}
