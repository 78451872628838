/* src/components/FormBuilder/Fields/TextField.css */

.form-group {
  margin-bottom: 15px;
  position: relative;
}

.form-group label {
  display: block;
  font-weight: 600;
  margin-bottom: 5px;
}

.form-control {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px !important;
}

.form-group.full-width {
  width: 100%;
}

.form-group.half-width {
  width: 48%; /* Adjust as needed, considering margins/gaps */
  display: inline-block;
  margin-right: 4%;
  vertical-align: top;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .form-group.full-width,
  .form-group.half-width {
    width: 100%;
    margin-right: 0;
  }
}

.required-asterisk {
  color: red;
}

.input-error {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 0.875rem;
}

.char-count {
  color: #6c757d;
  font-size: 0.875rem;
  margin-top: -5px;
}

/* New styles for form-footer */
.form-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.form-text {
  font-size: 0.875rem;
  color: #6c757d;
}
