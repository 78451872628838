.icon-map-selector {
  position: relative;
  display: inline-flex;
  cursor: pointer;
  font-family: Arial, sans-serif;
}

/* Force 40x40 only when NOT in preview mode */
.icon-map-selector:not(.preview-mode) {
  width: 40px;
  height: 40px;
}

/* PREVIEW MODE: big circular style */
.icon-map-selector.preview-mode {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-map-selector.preview-mode .icon-preview-circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s, box-shadow 0.2s;
}

.icon-map-selector.preview-mode .icon-preview-circle:hover {
  transform: translateY(-2px);
  box-shadow: inset 0 0 12px rgba(0, 0, 0, 0.1);
}

/* NON-PREVIEW: small square */
.icon-selector-square {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: border-color 0.2s, box-shadow 0.2s;
  overflow: hidden; /* So the placeholder won't break out */
}

/* Hover effect */
.icon-selector-square:hover {
  border-color: var(--main-color);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Truncated placeholder in the small square */
.icon-placeholder {
  display: inline-block;
  color: #999;
  font-size: 10px;
  line-height: 1.2;
  text-align: center;
  max-width: 70%; /* leaves room for the chevron */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Chevron in bottom-right corner */
.chevron-icon {
  font-size: 10px;
  position: absolute;
  right: 2px;
  bottom: 2px;
  color: #666;
}

/* Dropdown portal */
.icon-dropdown-portal {
  position: absolute;
  z-index: 3000;
}

/* Dropdown content container */
.icon-dropdown-content {
  width: 240px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
  animation: fadeIn 0.2s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Search bar inside the dropdown */
.icon-search {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.85rem;
  transition: border-color 0.2s;
}

.icon-search:focus {
  outline: none;
  border-color: var(--main-color);
}

/* Grid for icons */
.icon-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(36px, 1fr));
  gap: 8px;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 10px;
}

/* Each icon box */
.icon-dropdown-item {
  width: 36px;
  height: 36px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 0.8rem;
  transition: background-color 0.2s, border-color 0.2s;
}

/* "None" option */
.icon-dropdown-item.none-option {
  background-color: #fafafa;
  color: #555;
  font-size: 0.75rem;
  line-height: 1.2;
}

.icon-dropdown-item.none-option:hover {
  background-color: #f0f0f0;
  border-color: #ccc;
}

/* Hover on any icon item */
.icon-dropdown-item:hover {
  border-color: var(--main-color, #bb8300);
  background-color: #f7f7f7;
}
