/* bible-verse container */
.bible-verse {
    color: #fff;        /* Typically white text if on a dark background */
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    padding: 1rem;
  }
  
  /* We’ll define a simple fade-in animation */
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Apply the animation on mount */
  .fade-in {
    animation: fadeInUp 1s ease forwards;
  }
  
  .bible-verse-figure {
    margin: 1.5rem auto;
  }
  
  .bible-verse-text {
    font-size: 1.3rem;    /* Default larger text for readability on desktop */
    line-height: 1.5;
    font-style: italic;
    margin: 0;
    padding: 0 1rem;
    border-left: 4px solid rgba(255, 255, 255, 0.2);
  }
  
  .bible-verse-citation {
    margin-top: 0.75rem;
    font-size: 1.1rem;
    text-align: right;
    font-style: normal;
  }
  
  /* RESPONSIVE BREAKPOINTS */
  
  /* Reduce the text size for tablets / smaller screens */
  @media (max-width: 768px) {
    .bible-verse-text {
      font-size: 1.1rem; /* Slightly smaller */
    }
    .bible-verse-citation {
      font-size: 1rem;
    }
  }
  
  /* Further reduce the text size for very small screens (phones) */
  @media (max-width: 480px) {
    .bible-verse-text {
      font-size: 1rem; /* Smaller for mobile */
    }
    .bible-verse-citation {
      font-size: 0.9rem;
    }
  }
  