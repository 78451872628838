/* src/components/ResetPasswordConfirmationModal.css */

.reset-confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .reset-confirmation-actions {
    margin-top: 20px;
    display: flex;
    gap: 20px;
  }
  