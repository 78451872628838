/* src/components/MessageSender.css */

/* Container for the entire message sender */
.message-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: var(--background-color);
  min-height: 60vh;
  text-align: center;
  color: var(--main-color);
}

.message-recovery-container {
  width: 100%;
  max-width: var(--max-width);
  background-color: #f8f9fa;
  padding: 30px;
  border-radius: var(--border-radius);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.message-recovery-container h2.messageTitle {
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: var(--main-color);
  text-align: center;
  font-weight: var(--font-weight-bold);
}

.message-sender-tabs {
  width: 100%;
}

.message-sender-tabs .tabs {
  display: flex;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 20px;
}

.message-sender-tabs .tabs button {
  flex: 1;
  padding: 10px 15px;
  background-color: transparent;
  border: none;
  border-bottom: 3px solid transparent;
  font-size: 1rem;
  color: var(--secondary-color);
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s, border-bottom 0.3s;
}

.message-sender-tabs .tabs button:hover {
  color: #fff;
  background-color: var(--main-color);
}

.message-sender-tabs .tabs button.active {
  color: #fff;
  background-color: var(--main-color);
  border-bottom: 3px solid var(--main-color);
}

.login-form {
  display: flex;
  flex-direction: column;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.date-time-group {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.date-time-group .form-group {
  flex: 1;
}

.login-form > *:not(:last-child) {
  margin-bottom: 15px;
}

.action-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
  flex-wrap: wrap;
}

.action-buttons .action-button {
  flex: none;
}

.message-history {
  width: 100%;
}

.message-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.message-item {
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: var(--border-radius);
  padding: 20px;
  margin-bottom: 15px;
  text-align: left;
  transition: box-shadow 0.3s, background-color 0.3s;
  position: relative;
}

.message-item:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Keep header in a row at all times so the toggle is on the far right */
.message-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.message-collapsed-view {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1; /* This makes the left section take remaining space, pushing toggle button right */
}

.message-collapsed-view .message-date {
  font-size: 0.875rem;
  color: #6c757d;
}

.message-type-badge {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.75rem;
  font-weight: 600;
  color: #fff;
  margin-right: 10px;
  text-transform: uppercase;
}

.message-type-badge.direct {
  background-color: var(--main-color);
}

.message-type-badge.scheduled {
  background-color: var(--secondary-color);
}

.status-badge {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.75rem;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  background-color: var(--main-color);
}

.message-image-container {
  text-align: center;
}

.message-image-thumb {
  max-width: 40px;
  max-height: 40px;
  object-fit: cover;
  border-radius: var(--border-radius);
  display: inline-block;
  vertical-align: middle;
  transition: transform 0.3s;
}

.message-image-thumb:hover {
  transform: scale(1.05);
}

/* Chevron expand/collapse button - always at the right end */
.toggle-details-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  color: var(--main-color);
  font-size: 1.2rem;
  transition: transform 0.3s ease;
}

.toggle-details-button.expanded {
  transform: rotate(180deg);
}

.message-details {
  margin-top: 15px;
  padding: 15px;
  border: 1px solid #e9ecef;
  border-radius: var(--border-radius);
  background-color: #fdfdfd;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.message-item.expanded .message-details {
  opacity: 1;
  height: auto;
}

.expanded-message-content {
  margin-bottom: 10px;
  font-size: 1.05rem;
  font-weight: 600;
  color: var(--main-color);
}

.expanded-message-title {
  margin-bottom: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--main-color);
}

.message-item.expanded {
  background-color: #fdfdfd;
  border-color: #ced4da;
}

.image-preview {
  position: relative;
  display: inline-block;
}

.image-preview img {
  max-width: 100%;
  border-radius: var(--border-radius);
}

.image-preview .remove-image {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: var(--danger-color);
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px;
}

.error-message {
  color: var(--danger-color);
  margin-top: 10px;
  font-size: 0.9rem;
}

.toast-container {
  position: fixed;
  top: 70px;
  right: 20px;
  z-index: 9999;
}

.toast {
  background-color: #fff;
  border: 1px solid #ced4da;
  padding: 15px 20px;
  border-radius: var(--border-radius);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.toast.success {
  border-color: var(--success-color);
}

.toast.warning {
  border-color: var(--danger-color);
}

.toast .toast-message {
  color: var(--text-color);
  flex-grow: 1;
}

.toast .close-button {
  background: transparent;
  border: none;
  font-size: 1.2rem;
  color: var(--text-color);
  cursor: pointer;
  margin-left: 10px;
}

.progress-modal-content {
  text-align: center;
  color: var(--text-color);
}

.progress-modal-content p {
  margin-bottom: 10px;
}

.progress-modal-content progress {
  width: 100%;
  height: 20px;
  margin-bottom: 10px;
}

.recipients-line {
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
  font-size: 0.9rem;
  color: #495057;
  font-weight: 500;
  margin-top: 10px;
}

.recipients-chevron {
  display: inline-block;
  cursor: pointer;
  font-size: 0.8rem;
  padding: 2px 6px;
  margin-left: 5px;
  background: var(--main-color);
  color: #fff;
  border-radius: var(--border-radius);
  transition: transform 0.3s;
}

.recipients-chevron.open {
  transform: rotate(180deg);
}

.recipients-list {
  list-style: none;
  margin: 10px 0 0 0;
  padding: 10px;
  background: #fff;
  border: 1px solid #dee2e6;
  border-radius: var(--border-radius);
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  max-height: 150px;
  overflow-y: auto;
}

.recipient-item {
  font-size: 0.9rem;
  color: var(--main-color);
  margin-bottom: 5px;
  padding: 3px 0;
}

.recipient-item:hover {
  background-color: #f8f9fa;
}

/* Responsive Design */
@media (max-width: 768px) {
  .message-recovery-container {
    padding: 15px;
  }

  .message-recovery-container h2 {
    font-size: 1.5rem;
  }

  .message-sender-tabs .tabs button {
    font-size: 0.9rem;
    padding: 8px 5px;
  }

  .message-header {
    flex-direction: row;
    align-items: center;
  }

  .message-collapsed-view {
    flex-wrap: wrap;
  }

  .action-buttons {
    flex-direction: column;
    align-items: stretch;
  }

  .action-buttons .action-button {
    width: 100%;
  }

  .date-time-group {
    flex-direction: column;
  }

  .date-time-group .form-group {
    width: 100%;
  }

  .message-item {
    padding: 15px;
  }

  .expanded-message-content {
    font-size: 1rem;
  }

  .recipients-line {
    font-size: 0.85rem;
  }

  .recipients-list {
    font-size: 0.85rem;
  }

  .recipient-item {
    font-size: 0.85rem;
  }

  .message-type-badge,
  .status-badge {
    padding: 3px 5px;
    font-size: 0.7rem;
    margin-right: 2px;
  }
}
