/* src/components/Common/ActionButton.css */

.action-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 1rem;
  white-space: nowrap;
  text-decoration: none;
  background-color: var(--main-color);
}

.action-button.primary {
  background-color: var(--main-color);
}

.action-button.primary:hover:not(:disabled) {
  background-color: var(--main-color-hover-color);
}

.action-button.secondary {
  background-color: #6c757d;
}

.action-button.secondary:hover:not(:disabled) {
  background-color: #5a6268;
}

.action-button:hover:not(:disabled) {
  opacity: 0.9;
}

.action-button.disabled,
.action-button:disabled,
.action-button button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.action-button.icon-only {
  justify-content: center;
  padding: 0;
  width: 40px;
  height: 40px;
}

.action-button .button-text {
  margin-left: 8px;
}

.action-button .button-icon {
  width: 16px;
  height: 16px;
}

.action-button.size-small {
  height: 32px;
  font-size: 0.875rem;
}

.action-button.size-small:not(.split-button) {
  padding: 0 12px;
}

.action-button.size-medium {
  height: 40px;
  font-size: 1rem;
}

.action-button.size-medium:not(.split-button) {
  padding: 0 16px;
}

.action-button.size-large {
  height: 48px;
  font-size: 1.125rem;
}

.action-button.size-large:not(.split-button) {
  padding: 0 20px;
}

.action-button.full-width {
  width: 100%;
}

/* Split Button Styles */
.action-button.split-button {
  display: inline-flex;
  overflow: hidden;
  border-radius: 4px;
}

.action-button.split-button .main-button,
.action-button.split-button .split-button-toggle {
  background-color: inherit;
  color: inherit;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

/* Subtle dividing line between main and split buttons */
.action-button.split-button .main-button {
  border-radius: 4px 0 0 4px;
  padding: 0 15px;
}

.action-button.split-button .split-button-toggle {
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0 4px 4px 0;
  padding: 0 12px;
}

/* Hover states for split buttons */
.action-button.split-button .main-button:hover:not(:disabled),
.action-button.split-button .split-button-toggle:hover:not(:disabled) {
  background-color: var(--main-color-hover-color);
}

/* Focus states for split buttons - accessible outlines */
.action-button.split-button .main-button:focus,
.action-button.split-button .split-button-toggle:focus {
  outline: 2px solid rgba(255, 255, 255, 0.6);
  outline-offset: 2px;
}

.action-button.split-button .split-button-icon {
  font-size: 1em;
}

/* Adjustments for full-width split buttons */
.action-button.split-button.full-width {
  width: 100%;
}

.action-button.split-button.full-width .main-button {
  flex: 1;
  width: auto;
}

.action-button.split-button.full-width .split-button-toggle {
  flex: 0 0 auto;
}

/* Responsive Design */
@media (max-width: 768px) {
  .action-button {
    width: 100%;
    justify-content: center;
  }

  /* Keep the split button components side by side */
  .action-button.split-button {
    flex-direction: row;
    width: 100%;
  }

  .action-button.split-button .main-button,
  .action-button.split-button .split-button-toggle {
    width: auto;
    flex: 1;
    padding: 0 10px;
  }

  .action-button.split-button .split-button-toggle {
    flex: 0 0 auto;
    padding: 0 10px;
  }

  .action-button.split-button.full-width .main-button {
    flex: 1;
    width: auto;
  }

  .action-button .button-text {
    margin-left: 4px;
  }

  .action-button.size-small,
  .action-button.size-medium,
  .action-button.size-large {
    height: auto;
    font-size: 1rem;
  }
}
