/* src/components/Teams/TeamForm.css */

.teams-modal-form {
  display: flex;
  flex-direction: column;
}

.teams-form-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px;
}

.teams-input-group {
  margin-bottom: 15px;
}

.teams-input-group label {
  display: block;
  font-weight: bold;
  color: #000;
}

.teams-member-select-row {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
}

.select-wrapper {
  flex: 1;
}

.search-wrapper {
  width: 100%;
  margin-bottom: 10px;
}

/* Table thumbnail styling */
.team-form-photo-wrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--main-color);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-form-photo {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.team-form-no-photo {
  width: 100%;
  height: 100%;
  background-color: var(--main-color);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.teams-submit-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
