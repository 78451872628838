/* src/App.css */


/* Use CSS variables for styling */
.app-header {
  background-color: var(--main-color) !important;
  color: white;
  text-align: center;
  padding: 20px;
}

.header-image {
  max-width: 100px; /* Adjust as needed */
  height: auto;
  margin-bottom: 10px;
}

h1 {
  margin: 0;
  font-size: 2em;
}

h2 {
  margin: 0;
  font-size: 1.5em;
}
