/* src/pages/ConfigureFieldsModal.css */

.configure-fields-modal .modal-content-wrapper {
    position: static;
    max-height: 70vh;
    overflow-y: auto;
    padding: 20px;
  }
  
  .fields-list {
    margin-bottom: 20px;
  }
  
  .fields-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  
  .field-config {
    display: flex;
    flex-direction: column;
    background-color: #ffffff !important;
    border: 1px solid #e0e0e0 !important;
    border-radius: 8px;
    padding: 15px;
    position: relative;
    width: calc(50% - 15px); /* Adjust width for half-width items and gap */
  }

  .add-field-btn {
    display: flex;
    flex-direction: column;
    background-color: #ffffff !important;
    border: 1px solid #e0e0e0 !important;
    border-radius: 8px;
    position: relative;
    width: calc(50% - 15px); /* Adjust width for half-width items and gap */
  }
  
  .field-config:hover {
    background-color: #f9f9f9;
  }
  
  .field-details {
    flex: 1;
  }
  
  .field-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .field-row label {
    width: 80px;
    font-weight: bold;
    color: #333 !important;
  }
  
  /* Adjust the width of labels for better alignment */
  .field-row label {
    width: 100px;
  }
  
  .field-row .switch {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 24px;
    margin-right: 15px; /* Add margin for spacing */
  }
  
  .field-row input[type='text'] {
    flex: 1;
    padding: 8px 10px;
    border: 1px solid #ced4da;
    border-radius: 6px;
    font-size: 1em;
    color: #495057 !important;
  }
  
  .field-row input[type='text']::placeholder {
    color: #adb5bd !important;
  }
  
  .field-select {
    flex: 1;
  }
  
  .react-select__control {
    min-height: 40px;
  }
  
  .react-select__multi-value {
    background-color: var(--main-color) !important;
    color: #fff !important;
  }
  
  .react-select__multi-value__label {
    color: #fff !important;
  }
  
  .react-select__multi-value__remove {
    color: #fff !important;
  }
  
  .field-row .switch {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 24px;
  }
  
  .field-row .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .field-row .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc !important;
    transition: 0.4s;
    border-radius: 24px;
  }
  
  .field-row .slider:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white !important;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  .field-row input:checked + .slider {
    background-color: var(--main-color) !important;
  }
  
  .field-row input:checked + .slider:before {
    transform: translateX(22px);
  }
  
  .field-actions {
    margin-top: auto;
    display: flex;
    justify-content: flex-end;
  }
  
  .add-field-config {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .add-field-config .add-field-action-button {
    width: 100%;
    height: 100%;
  }
  
  .modal-actions {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
  }
  
  .modal-actions .full-width-button {
    width: 100%;
  }

  .hideMobileBlock{
    margin-left: 5px;
    margin-right: 5px;
  }
  
  @media (max-width: 768px) {
    .field-config {
      width: 100%;
    }
  
    .field-row {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .field-row label {
      width: 100%;
      margin-bottom: 5px;
    }
  
    .field-config {
      flex-direction: column;
    }
  }
  
  .edit-field-modal .edit-field-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .edit-field-modal .field-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .edit-field-modal .field-row label {
    width: 120px;
    font-weight: bold;
    color: #333 !important;
  }
  
  .edit-field-modal .field-row input[type='text'],
  .edit-field-modal .field-select {
    flex: 1;
    padding: 8px 10px;
    border: 1px solid #ced4da;
    border-radius: 6px;
    font-size: 1em;
    color: #495057 !important;
  }
  
  .edit-field-modal .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

