/* src/styles/variables.css */

:root {
    --main-color: #bb8300; /* Default primary color */
    --secondary-color: #31423c; /* Default secondary color */
    --background-color: #f5f5f5;
    --text-color: #333;
    --header-bg-color: #ddd;
    --button-bg-color: var(--main-color);
    --button-text-color: #fff;
    --button-hover-bg-color: var(--secondary-color);
    --max-width: 1600px;
    --font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  