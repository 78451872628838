/* src/components/UserForm.css */

:root {
  --main-color: #bb8300;
  --secondary-color: #31423c;
  --bg-color: #f9f9f9;
  --card-bg: #fff;
  --text-color: #333;
  --border-radius: 8px;
  --box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.users-modal-form {
  display: flex;
  flex-direction: column;
}

.enhanced-user-form {
  width: 100%;
}

.user-form-content {
  background: var(--card-bg);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.user-form-manual,
.user-form-members {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.users-input-group {
  display: flex;
  flex-direction: column;
}

.users-label,
.users-input-group label {
  font-weight: bold;
  color: #555;
  font-size: 0.95rem;
  margin-bottom: 5px;
}

.users-input-group input,
.users-input-group select,
.icon-map-selector,
.icon-search {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: var(--border-radius);
  font-size: 0.95rem;
  background: #fff;
  transition: border-color 0.2s;
}

.users-input-group input:hover,
.users-input-group select:hover {
  border-color: var(--main-color);
}

.users-input-group input:focus,
.users-input-group select:focus {
  outline: none;
  border-color: var(--main-color);
}

.users-submit-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Disabled Button Styles */
.action-button[disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}

.action-button[disabled]:hover {
  background-color: #ccc;
}

/* react-select classes for styling */
.react-select__control {
  border-radius: var(--border-radius) !important;
  border-color: #ddd !important;
  transition: border-color 0.2s;
  font-size: 0.95rem;
}

.react-select__control:hover {
  border-color: var(--main-color) !important;
}

.react-select__option--is-selected {
  background-color: var(--main-color) !important;
  color: #fff !important;
}

.react-select__option--is-focused {
  background-color: #f0f0f0 !important;
  color: #333 !important;
}
