/* src/components/Logo.css */

.logo-container {
    max-width: var(--max-width);
    margin: 0 auto;
    text-align: center;
  }
  
  .app-logo {
    height: 120px;
  }
  
  @media (max-width: 768px) {
    .app-logo {
      position: relative;
      top: -10px;
      height: 50px;
    }
  }
  