/* src/components/Footer.css */

.app-footer {
    background-color: var(--header-bg-color);
    padding: 20px 0;
    text-align: center;
    border-top: 1px solid #ddd;
    width: 100%;
  }
  
  .app-footer p {
    color: var(--main-color);
    font-size: 0.9em;
    margin: 0; /* Ensure there's no default margin */
  }
  
  /* Media queries for responsiveness */
  
  /* Tablets (portrait and landscape) */
  @media (max-width: 1024px) {
    .app-footer {
      padding: 15px 0;
    }
  
    .app-footer p {
      font-size: 0.85em;
    }
  }
  
  /* Mobile devices */
  @media (max-width: 768px) {
    .app-footer {
      padding: 10px 0;
    }
  
    .app-footer p {
      font-size: 0.8em;
    }
  }
  