/* src/components/Common/DeleteConfirmationModal.css */

.delete-confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.delete-confirmation-actions {
  margin-top: 20px;
  display: flex;
  gap: 20px;
}

.confirm-button,
.cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirm-button {
  background-color: #dc3545; /* Bootstrap Danger Color */
  color: #fff;
}

.confirm-button:hover:not(:disabled) {
  background-color: #c82333;
}

.cancel-button {
  background-color: #6c757d; /* Bootstrap Secondary Color */
  color: #fff;
}

.cancel-button:hover:not(:disabled) {
  background-color: #5a6268;
}

/* Spinner icon adjustments */
.confirm-button .button-icon {
  margin-right: 8px; /* Space between spinner and text */
}

.confirm-button .button-text {
  display: inline;
}

/* Disabled button styles */
.confirm-button:disabled,
.cancel-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.confirm-button:disabled:hover,
.cancel-button:disabled:hover {
  background-color: #ccc;
}
