.share-tab-container {
  padding: 20px; /* Reduced from 30px */
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08); /* Reduced shadow */
  color: var(--main-color, #343a40);
}

.share-tab-title {
  text-align: center;
  font-size: 1.5rem; /* Reduced from 2rem */
  margin-bottom: 10px;
  color: var(--secondary-color, #495057);
}

.share-tab-description {
  text-align: center;
  font-size: 0.95rem; /* Slightly reduced font size */
  color: var(--text-muted, #6c757d);
  margin-bottom: 20px; /* Reduced from 30px */
}

.share-methods {
  display: grid;
  grid-template-columns: 1fr; /* Stack vertically by default */
  gap: 20px; /* Reduced gap from 30px */
}

.share-method-card {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 15px; /* Reduced from 20px */
  text-align: center;
  transition: box-shadow 0.2s ease, transform 0.2s ease;
}

.share-method-card:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Reduced shadow intensity */
  transform: translateY(-3px); /* Reduced movement */
}

.share-method-title {
  font-size: 1.3rem; /* Reduced from 1.5rem */
  margin-bottom: 10px; /* Reduced from 15px */
  color: var(--secondary-color, #495057);
}

.share-url-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.share-url-input {
  width: 100%;
  max-width: 350px; /* Reduced from 400px */
  padding: 8px 12px; /* Reduced padding */
  font-size: 0.95rem; /* Slightly reduced font size */
  border: 1px solid #ced4da;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.share-url-input:focus {
  border-color: #80bdff;
  outline: none;
}

.qr-code-container {
  margin: 15px 0; /* Reduced from 20px */
  display: flex;
  justify-content: center;
}

.download-qr-button {
  display: block;
  margin: 0 auto;
}

/* Responsive Adjustments */
@media (min-width: 600px) {
  .share-methods {
    grid-template-columns: 1fr 1fr; /* Two columns on larger screens */
  }
}
