/* Reuse color variables from your existing design */
:root {
  --main-color: #bb8300;       /* Gold-ish primary color */
  --secondary-color: #31423c;  /* Deep green-ish secondary color */
  --light-bg: #f9f9f9;
  --text-color: #fff;

  --button-bg-color: var(--main-color);
  --button-hover-bg-color: var(--secondary-color);

  --input-border-color: #ddd;
  --step-dotted-line-color: #ccc;
  --step-active-line-color: var(--main-color);
}

/* Overall 2-panel layout */
.subscription-split-container {
  display: flex;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(
    135deg,
    var(--main-color) 20%,
    var(--secondary-color) 100%
  );
}

/* LEFT PANEL */
.subscription-left-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px;
  background: #fff; /* White background */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

  opacity: 0;
  animation: fadePanelLeft 0.4s forwards;
}
@keyframes fadePanelLeft {
  to {
    opacity: 1;
    transform: translateX(0);
  }
  from {
    transform: translateX(-30px);
  }
}

/* Catholicore logo top-left */
.subscription-left-logo {
  position: absolute;
  top: 20px;
  left: 20px;
}
.subscription-left-logo img {
  width: 12vw;
  max-width: 180px;
  height: auto;
}

/* Main content of the left panel */
.subscription-left-content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 700px;
  margin: 80px auto 40px;
  width: 100%;
  text-align: center;
}

/* Title & subtitle for the plan section */
.subscription-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: var(--main-color);
}
.subscription-subtitle {
  margin-bottom: 2rem;
  font-size: 1rem;
  color: #555;
  max-width: 80%;
}

/* Container for plan cards */
.plan-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  width: 100%;
}

/* Each plan card:
   - Adjust width to ~30% so 3 fit on one row (on wider screens).
   - Add a min-width so they don’t crush on smaller screens.
*/
.plan-card {
  background-color: #fdfdfd;
  border: 1px solid #ddd;
  border-radius: 8px;
  flex: 1 1 calc(30% - 20px);
  min-width: 240px;
  max-width: 320px;
  padding: 20px 15px;
  box-shadow: 0 4px 10px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
  position: relative; /* needed for "Most Popular" badge */
  margin: 0 auto;
}
.plan-card:hover {
  transform: translateY(-5px);
}

/* "Most Popular" badge */
.most-popular {
  border: 2px solid var(--main-color);
}
.most-popular .badge {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: var(--main-color);
  color: #fff;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 0.75rem;
  font-weight: 600;
}

/* Shake animation for standard plan if no plan is selected */
.shake {
  animation: shake-animation 20s infinite;
}
@keyframes shake-animation {
  0%, 100% { transform: translate(0, 0); }
  10%, 30%, 50%, 70%, 90% { transform: translate(-3px, 0); }
  20%, 40%, 60%, 80% { transform: translate(3px, 0); }
}

/* Plan name, price, & features */
.plan-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #333;
}
.plan-price {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--secondary-color);
  margin-bottom: 0.75rem;
}
.plan-features {
  list-style: none;
  padding: 0;
  margin: 0 0 1rem;
  text-align: center;
  color: #555;
  font-size: 0.9rem;
  line-height: 1.4;
}
.plan-features li {
  margin: 0.25rem 0;
}

/* Buttons for the plan forms */
.plan-button,
.subscription-button {
  background-color: var(--button-bg-color);
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 10px 16px;
  font-weight: 600;
  font-size: 0.95rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.plan-button:disabled,
.subscription-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.plan-button:hover:enabled,
.subscription-button:hover:enabled {
  background-color: var(--button-hover-bg-color);
}

.plan-card.selected-plan {
  border: 3px solid #bb8300; /* e.g., a green border */
  box-shadow: 0 0 10px #bb8300;
}

.plan-name span {
  color: #bb8300;
  font-size: 0.85rem;
  margin-left: 0.5rem;
}

/* RIGHT PANEL */
.subscription-right-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 20px;
  background-color: rgba(255, 255, 255, 0.05);

  opacity: 0;
  animation: fadePanelRight 0.4s forwards;
  animation-delay: 0.1s;
}
@keyframes fadePanelRight {
  to {
    opacity: 1;
    transform: translateX(0);
  }
  from {
    transform: translateX(30px);
  }
}

/* Container for right panel content */
.subscription-right-content {
  max-width: 480px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.13);
  backdrop-filter: blur(5px);
  padding: 1.5rem;
  border-radius: 10px;
  color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
}
.subscription-right-content h3 {
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
}

.subscription-image-box {
  margin-top: 1.5rem;
}
.subscription-image-box img {
  max-width: 100%;
  border-radius: 8px;
}

/* Responsive Adjustments */
@media (max-width: 992px) {
  .subscription-split-container {
    flex-direction: column;
  }
  .subscription-left-panel,
  .subscription-right-panel {
    width: 100%;
    animation: none;
    transform: none;
    opacity: 1;
  }
  .subscription-left-logo {
    position: static;
    text-align: center;
    margin-bottom: 20px;
  }
  .subscription-left-logo img {
    width: 30vw;
    max-width: 150px;
  }
  .plan-card {
    flex: 1 1 100%;
    max-width: 350px;
    margin-bottom: 20px;
  }
}

@media (prefers-reduced-motion: reduce) {
  .subscription-left-panel,
  .subscription-right-panel {
    animation: none !important;
  }
  .plan-card:hover {
    transform: none !important;
  }
}

/* Back button in top-right corner of the LEFT panel */
.subscription-back-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
  font-size: 1.25rem; /* big enough to see the icon */
  display: flex;
  align-items: center;
  gap: 0.4rem; /* space between icon and text if you want text */
}

.subscription-back-button:hover {
  color: var(--main-color);
}

/* Additional styling for the enterprise contact form inside the modal */
.enterprise-modal-body {
  padding: 1rem;
  text-align: left;
}

.enterprise-contact-form {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 1rem;
}

.enterprise-contact-form label {
  font-weight: 600;
}

.enterprise-contact-form input,
.enterprise-contact-form textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--input-border-color);
  border-radius: 4px;
}

.enterprise-submit-btn {
  align-self: flex-end;
  background-color: var(--button-bg-color);
  color: #fff;
  border: none;
  padding: 0.6rem 1.2rem;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.enterprise-submit-btn:hover {
  background-color: var(--button-hover-bg-color);
}
