/* Container styling */
.checkbox-field {
  margin-bottom: 15px;
}

/* Label styling */
.checkbox-label {
  font-size: 1rem;
  color: var(--main-color, #333);
  font-weight: 600;
  padding-bottom: 7px;
  font-family: var(--font-family, Arial, sans-serif);
  display: block;
}

/* Options container */
.checkbox-options {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.checkbox-options.vertical {
  flex-direction: column;
}

.checkbox-options.horizontal {
  flex-direction: row;
}

/* Custom checkbox option */
.checkbox-option {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding-left: 30px;
  font-size: 0.95rem;
  color: var(#333);
}

/* Hide default checkbox input */
.checkbox-option input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Custom checkbox appearance */
.custom-checkbox {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 2px solid var(--main-color, #333);
  border-radius: 4px;
  transition: background-color 0.2s ease-in-out;
}

/* When checkbox is checked, change background color */
.checkbox-option input:checked ~ .custom-checkbox {
  background-color: var(--main-color, #333);
}

/* Draw the checkmark when checked and center it */
.checkbox-option input:checked ~ .custom-checkbox::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6px;
  height: 12px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -50%) rotate(45deg);
}

/* Space between custom checkbox and text */
.checkbox-text {
  margin-left: 8px;
}

/* Error message styling */
.error-message {
  color: #333;
  font-size: 0.9rem;
  margin-top: 5px;
}
