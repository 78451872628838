/* Root variables */
:root {
  --main-color: #bb8300;
  --secondary-color: #31423c;
  --input-border-color: #ddd;
  --button-bg-color: var(--main-color);
  --button-hover-bg-color: var(--secondary-color);
  --text-color: #fff;
}

/* Container to constrain Step 4 form width */
.step4-container {
  width: 100%;
  max-width: 900px; /* Adjust this value as needed */
  margin: 0 auto;
}

/* Basic form container */
.sign-up-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
  margin-top: 20px;
}

/* Input groups & row layout */
.sign-up-input-group {
  margin-bottom: 15px;
  text-align: left;
}
.sign-up-input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: var(--main-color);
}
.sign-up-input-group input,
.sign-up-input-group select {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid var(--input-border-color);
  border-radius: 5px;
  font-size: 1em;
  background-color: #fff;
  box-sizing: border-box;
}

/* Row wrapper for two-column layout with spacing */
.sign-up-input-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

/* Use flexible widths for two-column layout */
.sign-up-input-group.half-width {
  flex: 1 1 calc(50% - 10px);
}
.sign-up-input-group.full-width {
  width: 100%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .sign-up-input-row {
    flex-direction: column;
    gap: 0;
  }
  .sign-up-input-group.half-width {
    flex: 1 1 100%;
  }
}

/* Buttons */
.sign-up-submit-button {
  width: 100%;
  padding: 12px 15px;
  border: none;
  border-radius: 5px;
  background-color: var(--button-bg-color);
  color: #fff;
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}
.sign-up-submit-button:hover {
  background-color: var(--button-hover-bg-color);
}

/* Back button styles (if applicable) */
.sign-up-back-button {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid var(--main-color);
  border-radius: 5px;
  background-color: #fff;
  color: var(--main-color);
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.sign-up-back-button:hover {
  background-color: var(--main-color);
  color: #fff;
}

.error {
  border-color: red !important;
}

/* Error message styling */
.error-message {
  color: red;
  font-size: 0.9em;
  margin-top: 5px;
}

.required-asterisk {
  color: red;
  margin-left: 4px;
  display: inline;
}
